import * as React from 'react'
import { SearchIcon } from '../icons/SearchIcon'

type Props = {
    buttonLabel?: String,
    query: string,
    placeholder?: string,
    onPerformQuery: (query: string) => void,
    onQueryChanged?:(query: string) => void,
} & React.HTMLAttributes<HTMLDivElement>

export const SearchInput: React.FC<Props> = ({ buttonLabel, query, placeholder, onPerformQuery, onQueryChanged, className}: Props) => {

    const [currentQuery, setCurrentQuery] = React.useState<string>('');

    React.useEffect(() => {
        setCurrentQuery(query ?? '');
    }, [query]);


    const updateQuery = (query: string) => {
        setCurrentQuery(query);
        // Do autocomplete stuffs
        onQueryChanged && onQueryChanged(query);

    }

    const captureEnter = (event) => {
        if (event.key === "Enter") {
            // Cancel the default action, if needed
            event.preventDefault();
            executeQuery(currentQuery)();
        }
    }

    const executeQuery = (q: string) => () => {
        onPerformQuery && onPerformQuery(q);
    }

    const dispButtonLabel = buttonLabel ?? 'Rechercher';
    return <div className={`flex join border border-neutral w-full ${className}`}>
        <input className='join-item input input-sm flex-1 focus:outline-none focus:border-accent'
            placeholder={placeholder}
            value={currentQuery}
            onKeyDown={captureEnter}
            onChange={(e) => updateQuery(e.target.value)}></input>
        <button className='join-item btn btn-sm btn-accent'
            onClick={executeQuery(currentQuery)}>
            <SearchIcon></SearchIcon>
            <span>{dispButtonLabel}</span>
        </button>
    </div>
}