import * as React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Login, logOut } from './login/Login';

import { UserAuth } from '../../app/xplorer-app/bindings/UserAuth';

import { App } from './App';
import { Project, Workflow } from './core/Workflow';


import { ProjectSettings } from './projects/ProjectSettings';


import { Dialog, openDialog } from './ext/Dialog';
import { AppPage } from '@sleekly-ui/layout/AppPage';
import { AppState } from '@sleekly-ui/AppState';
import { UserAvatar } from '@sleekly-ui/components/UserAvatar';
import { LogoutIcon } from '@sleekly-ui/icons/LogoutIcon';
import { CloseIcon } from '@sleekly-ui/icons/CloseIcon';
import { AnalyticsPage } from './analytics/AnalyticsPage';

const router = createBrowserRouter([
    {
        path: "/",
        element: <App></App>,
    },
    {
        path: "/login",
        element: <Login defaultRoute="/">
            <div className='text-center font-bold'>XPlorer</div>
        </Login>,
    },
    {
        path: "/analytics",
        element: <AnalyticsPage></AnalyticsPage>,
    }
]);

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const Main: React.FC<Props> = ({ }: Props) => {

    const [user] = AppState.useState<UserAuth>("current-user");

    const [currentProject] = AppState.useState<Project>('current-project');

    // AppState.pushState('app-theme', 'light');

    React.useEffect(() => {
        if (!user) {
            AppState.pushState('current-project', null);
        }
    }, [user]);

    React.useEffect(() => {
        if (currentProject) {
            AppState.pushState('current-app-view', 'project-home');
        }else {
            AppState.pushState('current-app-view', 'select-projects');
        }
    }, [currentProject]);

    const closeProject = () => {
        AppState.pushState('current-project', null);
        Workflow.initialize();
    }

    const openProjectSettings = () => {
        openDialog('project-settings');
    }

    return <AppPage>
        <div className='flex items-center bg-base-300 h-16 px-4 space-x-2'>
            <div className='flex items-end space-x-2'>
                <div className='font-bold text-2xl'>XPlorer</div>
                {
                    currentProject && <>
                        <div className='flex items-center space-x-2 ml-8'>
                            <div>-</div>
                            <div className='text-lg hover:text-primary cursor-pointer'
                                onClick={openProjectSettings}>
                                {currentProject.title}
                            </div>

                            <button className='btn btn-xs btn-ghost btn-circle'
                                onClick={closeProject}
                            >
                                <CloseIcon></CloseIcon>
                            </button>
                        </div>
                    </>
                }
            </div>
            <div className='flex-1'></div>
            {
                user && <>
                    <UserAvatar userName={user.full_name}></UserAvatar>
                    <button className='btn btn-sm btn-square btn-ghost'
                        onClick={logOut}>
                        <LogoutIcon></LogoutIcon>
                    </button>
                </>
            }
        </div>
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
        <Dialog dialogId="project-settings"
            title='Paramètres du projet'>
            <ProjectSettings project={currentProject}></ProjectSettings>
            <form method="dialog" className='flex justify-center mt-4'>
                <button className='btn btn-sm btn-accent'>Enregistrer</button>
            </form>
        </Dialog>
    </AppPage>
}