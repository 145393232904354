import * as React from 'react'
import { PinControls, PinControlsAttributes } from './PinControls'
import { LinkedInIcon } from '@sleekly-ui/icons/LinkedInIcon'

export type ContactOverview = {
    name: string,
    description: string,
    url: string
}

type Props = {
    contact: ContactOverview,
    compact?: boolean
} & React.HTMLAttributes<HTMLDivElement> & PinControlsAttributes

export const CandidateContactCard: React.FC<Props> = ({ contact,
    compact,
    isPinned,
    onDelete,
    onPin, }: Props) => {

    return <div
        className='shadow-md bg-base-200 rounded-md p-2'>
        <div className='flex mb-1 space-x-2 '>
            <span className='mt-1'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                    className='h-4 w-4'>
                    <path d="M11 14.0619V20H13V14.0619C16.9463 14.554 20 17.9204 20 22H4C4 17.9204 7.05369 14.554 11 14.0619ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13Z"></path>
                </svg>
            </span>
            <div className='font-bold'>
                <span>{contact.name}</span>
            </div>
            <a className='btn btn-circle btn-xs btn-ghost'
                target='blank'
                href={contact.url}>
                <LinkedInIcon></LinkedInIcon>
            </a>
            <div className='flex-1'></div>
            <PinControls
                data={contact}
                isPinned={isPinned}
                onPin={onPin}
                onDelete={onDelete}
            ></PinControls>
        </div>

        {
            !compact && <div className='text-sm'>{contact.description}</div>
        }

    </div>
}