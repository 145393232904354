import * as React from 'react'

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const Card:React.FC<Props>  = ({ children, className, onClick}: Props) => {

    return <div className={`shadow-md bg-base-200 rounded-md p-2 m-2 ${className}`}
    onClick={onClick}>
        {children}
    </div>
}