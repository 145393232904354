import * as React from 'react'
import { Services } from '../core/Services';
import { AppState } from '@sleekly-ui/AppState';
import { openDialog } from '../ext/Dialog';
import { NEW_PROJECT_DIALOG, NewProjectDialog } from './NewProjectDialog';
import { Project } from '../core/Workflow';
import { UserGroup } from '@sleekly-ui/icons/UserGroupIcon';
import * as moment from 'moment';

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const Projects: React.FC<Props> = ({ }: Props) => {

    const [projects, setProjects] = React.useState([]);

    const [newProjectId, setNewProjectId] = React.useState('');

    React.useEffect(() => {
        load();
    }, []);

    const load = async () => {
        const projects = await Services.loadProjects();
        
        setProjects(projects);
    }

    const openProject = (project) => () => {
        AppState.pushState('current-project', project);
        AppState.pushState('app-activity', 'project-summary');
    }
    
    const newProject = () => {
        setNewProjectId(moment().toISOString());
        openDialog(NEW_PROJECT_DIALOG);
    }

    const createProject = (project: Project, activityName: string) => {
        AppState.pushState('current-project', project);
        if (activityName) {
            AppState.pushState('app-activity', activityName);
        }
    }

    const recentProjects = projects.slice(0, 3);
    const oldProjects = projects.slice(3, projects.length);

    return <div className='flex-1 flex flex-col items-center overflow-hidden'>
        <div className='max-w-screen-lg flex flex-col overflow-hidden'>

            <div className='p-2 flex my-4'>
                <input className='input input-sm input-bordered w-full max-w-md'
                    placeholder='Filter les projets'></input>
                <div className='flex-1'></div>
                <button className='btn btn-primary btn-sm'
                    onClick={newProject}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                        className='h-5 w-5'>
                        <path d="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM11 11H7V13H11V17H13V13H17V11H13V7H11V11Z"></path>
                    </svg>
                    <span>Nouveau projet</span>
                </button>
            </div>


            <div className='flex-1 overflow-auto'>
                <div className='text-xs font-bold opacity-50 mt-4 mb-2'>Projets récents</div>
                <div className='grid grid-cols-3 gap-4 w-full max-w-screen-lg '>
                    {
                        recentProjects.map(project => {
                            return <div key={project.id}
                                className='flex flex-col min-w-xs max-w-sm p-4 shadow-lg bg-base-200 cursor-pointer rounded-md'
                                onClick={openProject(project)}>
                                <div className='flex items-center'>
                                    <div className='flex-1 text-xl font-bold'> {project.title}</div>
                                    <div className="tooltip tooltip-left" data-tip="Partagé avec 5 Personnes">
                                        <UserGroup></UserGroup>
                                    </div>
                                </div>
                                <div className='flex-1 text-sm opacity-50'>
                                    <span className='line-clamp-3'>{project.description}</span>
                                </div>
                                <div className='flex items-baseline'>
                                    <div className='flex-1'>
                                        <div className='text-xs '>
                                            <span className='font-bold'>33 </span>
                                            <span>Prospects</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="tooltip tooltip-left" data-tip="Dernière mise à jour">
                                            <div className='text-xs'>{moment().format("DD/MM/YYYY")}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
                <div className='text-xs font-bold opacity-50 mt-8 mb-2'>Projets anciens</div>
                <div className='grid grid-cols-3 gap-4 w-full max-w-screen-lg'>
                    {
                        oldProjects.map(project => {
                            return <div key={project.id}
                                className='flex flex-col min-w-xs max-w-sm p-4 shadow-lg bg-base-200 cursor-pointer rounded-md'
                                onClick={openProject(project)}>
                                <div className='flex items-center'>
                                    <div className='flex-1 text-xl font-bold'> {project.title}</div>
                                    <div className="tooltip tooltip-left" data-tip="Partagé avec 5 Personnes">
                                        <UserGroup></UserGroup>
                                    </div>
                                </div>
                                <div className='flex-1 text-sm opacity-50'>
                                    <span className='line-clamp-3'>{project.description}</span>
                                </div>
                                <div className='flex items-baseline'>
                                    <div className='flex-1'>
                                        <div className='text-xs '>
                                            <span className='font-bold'>33 </span>
                                            <span>Prospects</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="tooltip tooltip-left" data-tip="Dernière mise à jour">
                                            <div className='text-xs'>{moment().format("DD/MM/YYYY")}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>

        <NewProjectDialog newProjectId={newProjectId} onCreateProject={createProject}></NewProjectDialog>
    </div>
}