import * as React from 'react'

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const CompanyIcon: React.FC<Props> = ({ }: Props) => {

    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
        className='h-4 w-4'>
        <path d="M10 10.1111V1L21 7V21H3V7L10 10.1111Z"></path>
    </svg>
}