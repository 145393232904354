import * as React from 'react'

type Props = {
    dialogId: string,
    title?: string,
    closeButtonHidden?: boolean
} & React.HTMLAttributes<HTMLDivElement>

export const Dialog: React.FC<Props> = ({ dialogId, title, children, closeButtonHidden, className}: Props) => {

    return <dialog id={dialogId} className="modal">

        <div className={`modal-box w-6/12 max-w-5xl flex flex-col ${className}`}>
            {
                title && <>
                    <div className='flex items-center'>
                        <h3 className='font-bold text-lg flex-1 '>{title}</h3>
                        {
                            !closeButtonHidden && <form method="dialog">
                                <button className="btn btn-sm btn-circle btn-ghost focus:outline-none">✕</button>
                            </form>
                        }
                    </div>
                </>
            }

            {children}
        </div>
    </dialog>
}

export function openDialog(id: string) {
    (document.getElementById(id) as any).showModal()
}

export function closeDialog(id: string) {
    (document.getElementById(id) as any).close()
}