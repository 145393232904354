import * as React from 'react'
import { SearchContext } from '../core/Workflow'
import { SearchIcon } from '@sleekly-ui/icons/SearchIcon'
import { MagicIcon } from '@sleekly-ui/icons/MagicIcon'

import { AI } from '../core/AI'
import { CloseIcon } from '@sleekly-ui/icons/CloseIcon'
import { usePreviousState } from '@sleekly-ui/UsePreviousState'
import { ProgressIndicator } from '@sleekly-ui/components/ProgressIndicator'

type Props = {
    searchContext: SearchContext,
    onExecuteSearch: (context: SearchContext, query: string) => void
} & React.HTMLAttributes<HTMLDivElement>

export const CompanySearchInput: React.FC<Props> = ({ searchContext, onExecuteSearch }: Props) => {

    const [currentInput, setCurrentInput] = React.useState('');

    const [inProgress, setInProgress] = React.useState(false);

    const [keywordsSuggestions, setKeywordsSuggestions] = React.useState<string[]>([]);

    const previousContext = usePreviousState<SearchContext>(searchContext);

    React.useEffect(() => {
        setCurrentInput('');
        if (previousContext?.domain?.label !== searchContext.domain?.label) {
            setKeywordsSuggestions([]);
        }
        
    }, []);

    const executeSearch = (keyword: string) => () => {
        const newContext: SearchContext = Object.assign({}, searchContext, {
            additionnalKeywords: searchContext.additionnalKeywords.concat(keyword)
        });
        console.log(newContext);

        onExecuteSearch && onExecuteSearch(newContext, '');
    }

    const removeKeyword = (keyword: string) => () => {
        const newContext: SearchContext = Object.assign({}, searchContext, {
            additionnalKeywords: searchContext.additionnalKeywords.filter( k => k !== keyword)
        });
        console.log(newContext);

        onExecuteSearch && onExecuteSearch(newContext, '');
    }

    const captureEnter = (event) => {
        if (event.key === "Enter") {
            // Cancel the default action, if needed
            event.preventDefault();
            executeSearch(currentInput);
        }
    }

    const suggestKeywords = async () => {
        setInProgress(true);
        const suggestions = await AI.suggestKeywords('', searchContext);
        setKeywordsSuggestions(suggestions);
        setInProgress(false);
    }

    return <div className='w-full max-w-screen-md'>

        {
            searchContext?.additionnalKeywords.length > 0 && <div className='p-2 flex flex-wrap'>
                {
                    searchContext?.additionnalKeywords.map(keyword => {
                        return <div key={keyword}
                            className='flex space-x-2'>
                            <div className='badge cursor-pointer hover:text-accent badge-outline mx-1'
                            >
                                {keyword}
                                <button className='cursor-pointer'
                                    onClick={removeKeyword(keyword)}>
                                    <CloseIcon></CloseIcon>
                                </button>
                            </div>

                        </div>
                    })
                }
            </div>
        }

        <div className='flex items-center space-x-2'>

            <input className="input input-sm input-bordered flex-1"
                placeholder="Ajouter une précision"
                value={currentInput}
                onChange={(e) => setCurrentInput(e.target.value)}
                onKeyDown={captureEnter}></input>

            {
                currentInput.length > 0 && <div className='space-x-2 flex justify-center'>
                    <button className='btn btn-sm btn-accent'
                        onClick={executeSearch(currentInput)}>
                        <SearchIcon></SearchIcon>
                        <span>Rechercher</span>
                    </button>
                </div>

            }
            <div className="tooltip tooltip-left" data-tip="Suggérer des mots-clés supplémentaires">
                <button className='btn btn-sm'
                    onClick={suggestKeywords}>
                    <MagicIcon></MagicIcon>
                </button>
            </div>
        </div>
        <ProgressIndicator visible={inProgress}></ProgressIndicator>
        {
            keywordsSuggestions.length > 0 && <div className='p-2'>
                <div className='text-xs opacity-50 mb-1'>Suggestions</div>
                <div className='flex flex-wrap mb-1'>
                {
                    keywordsSuggestions
                    .filter(keyword => !searchContext.additionnalKeywords.includes(keyword))
                    .slice(0, Math.min(keywordsSuggestions.length, 10)).map(keyword => {

                        return <div key={keyword}>

                            <div className='badge cursor-pointer hover:text-accent badge-outline mx-1'
                                onClick={executeSearch(keyword)}>{keyword}</div>
                        </div>
                    })
                }
                </div>
            </div>
        }

    </div>
}
