import * as React from 'react'
import { PinnedCompany } from '../core/Workflow'

import { ScoreIndicator } from './ScoreIndicator';

import { CompanyInfoBadges } from './CompanyInfoBadges';

import { Card } from '@sleekly-ui/components/Card';
import { Tooltip } from '@sleekly-ui/components/Tooltip';
import { DataBadge } from '@sleekly-ui/components/DataBadge';
import { CompanyIcon } from '@sleekly-ui/icons/CompanyIcon';
import { UserGroup2Icon } from '@sleekly-ui/icons/UserGroup2Icon';
import { CommentIcon } from '@sleekly-ui/icons/CommentIcon';
import { WebIcon } from '@sleekly-ui/icons/WebIcon';
import { GoogleIcon } from '@sleekly-ui/icons/GoogleIcon';
import { LinkedInIcon } from '@sleekly-ui/icons/LinkedInIcon';

type Props = {
    compact?: boolean,
    company: PinnedCompany,
    selected: boolean,
    onSelectCompany: (company: PinnedCompany) => void
} & React.HTMLAttributes<HTMLDivElement>

export const CompanyTile: React.FC<Props> = ({ company, compact, selected, onSelectCompany }: Props) => {
    const selectedStyle = selected ? 'border border-primary' : '';
    return <Card
        className={`${selectedStyle}`}
        onClick={() => onSelectCompany && onSelectCompany(company)}>
        <div className={`flex items-center space-x-2`}>
            <CompanyIcon></CompanyIcon>
            <div className='text-sm line-clamp-1 flex-1 font-bold'>{company.name}</div>
            <div className='flex space-x-1 pr-4'>
                <a className='btn btn-circle btn-xs btn-ghost'
                    target='blank'
                    href={`https://www.google.com/search?q=${company.name}&btnI=1`}>
                    <WebIcon></WebIcon>
                </a>

                <a className='btn btn-circle btn-xs btn-ghost'
                    target='blank'
                    href={`https://www.google.com/search?q=${company.name}`}>
                    <GoogleIcon></GoogleIcon>
                </a>
                <a className='btn btn-circle btn-xs btn-ghost'
                    target='blank'
                    href={company.url}>
                    <LinkedInIcon></LinkedInIcon>
                </a>
            </div>

            <Tooltip text={'Priorité a contacter'} tooltipOrientation='left'>
                <ScoreIndicator scorePercent={80}></ScoreIndicator>
            </Tooltip>
        </div>
        {
            !compact && <>
                <div className='text-sm line-clamp-2 my-2'>
                    {
                        company.interest && <span>{company.interest}</span>
                    }
                    {
                        !company.interest && <span className='italic font-thin'>Intérêt non renseigné</span>
                    }
                </div>
                <div className='flex'>
                    <CompanyInfoBadges></CompanyInfoBadges>
                    <div className='flex-1'></div>
                    <DataBadge entries={[
                        {
                            icon: <UserGroup2Icon></UserGroup2Icon>,
                            value: '5 / 10',
                            tooltip: 'Contacts Contactés / Trouvés'
                        },
                        {
                            icon: <CommentIcon></CommentIcon>,
                            value: '5',
                            tooltip: 'Commentaires'
                        }
                    ]}
                        tooltipOrientation='left'></DataBadge>
                </div>
            </>
        }

    </Card>
}