import * as React from 'react'
import * as moment from 'moment'

import { CompanySearchInput } from '../../components/CompanySearchInput'

import { WebSearch } from '../../core/WebSearch'
import { CompanyOverview, DomainHistory, SearchContext, Workflow } from '../../core/Workflow'

import { CandidateCompaniesList } from '../../components/CandidateCompaniesList'

import { InfoIcon } from '@sleekly-ui/icons/InfoIcon'
import { ScreenTitle } from '@sleekly-ui/components/ScreenTitle'

type Props = {
    searchContext: SearchContext,
    onUpdateSearchContext: (searchContext: SearchContext) => void,
    onSelectedCompanyChanged: (company: CompanyOverview) => void
} & React.HTMLAttributes<HTMLDivElement>

export const CompanySearch: React.FC<Props> = ({ searchContext, onUpdateSearchContext, onSelectedCompanyChanged }: Props) => {

    const [candidateCompanies, setCandidateCompanies] = React.useState<CompanyOverview[]>([]);

    const [searchProgressIndicatorVisible, setSearchProgressIndicatorVisible] = React.useState(false);

    React.useEffect(() => {
        if (searchContext) {
            executeSearch(searchContext, '');
        }
    }, [searchContext]);

    const executeSearch = async (context: SearchContext, query: string) => {

        if (context) {
            const history: DomainHistory = {
                id: context.domain.id,
                domain: context.domain,
                keywords: [].concat(context.keyword, context.additionnalKeywords, query).filter(k => k && k?.length > 0),
                date: moment().toISOString()
            };
            Workflow.addDomainHistory(history);
            Workflow.addExploredDomain(context.domain, context.keyword);
        }


        setCandidateCompanies([]);
        setSearchProgressIndicatorVisible(true);
        const domain = context ? context.domain?.label : '';
        const keyword = context ? context.keyword : '';
        const additionalKeywords = context ? context.additionnalKeywords.join(' ') : '';
        const searchQuery = `${domain} ${keyword} ${query} ${additionalKeywords}`.trim();
        const searchResults = await WebSearch.webSearch(searchQuery, "linkedin.com/company");

        const companies: CompanyOverview[] = searchResults.results.map(res => {
            const name = res.title.replace(' | LinkedIn', '')
                .replace(' - LinkedIn', '');
            return {
                id: name,
                name,
                description: res.content,
                url: res.url
            }
        });
        setCandidateCompanies(companies);
        setSearchProgressIndicatorVisible(false);
    }

    const visibleCompanies = Workflow.filterDiscardedCompanies(candidateCompanies);

    const countSummary = candidateCompanies.length > 0 ? `(${visibleCompanies.length})` : '';

    return <>
        <ScreenTitle level='secondary'>
            <span>Entreprises Candidates</span>
            <span className='text-sm'> {countSummary}</span>
            <div className='flex-1'></div>
            <InfoIcon></InfoIcon>
        </ScreenTitle>

        <CompanySearchInput searchContext={searchContext}
            onExecuteSearch={onUpdateSearchContext}></CompanySearchInput>
        <div className='h-2'></div>
        <CandidateCompaniesList
            loading={searchProgressIndicatorVisible}
            companies={visibleCompanies}
            onItemSelectionChange={onSelectedCompanyChanged}
            searchContext={searchContext}
        ></CandidateCompaniesList>
    </>

}