import * as React from 'react'

import * as moment from 'moment'

import { CenterColumn, ColumnPageLayout, SectionLabel, SideColumn } from '../../ext/Layout'

import { SelectedCompaniesWidget } from '../../components/SelectedCompaniesWidget'
import { CompanyOverview, SearchHistory, Workflow } from '../../core/Workflow'
import { WebSearch } from '../../core/WebSearch'
import { CandidateCompaniesList } from '../../components/CandidateCompaniesList'

import { AI } from '../../core/AI'

import { AppState } from '@sleekly-ui/AppState'
import { Tooltip } from '@sleekly-ui/components/Tooltip'
import { SearchFiltersWidget } from '../../components/SearchFiltersWidget'
import { CompanyDetailsWidget } from '../../components/company-info/CompanyDetailsWidget'
import { logOut } from '../../login/Login'
import { ScreenTitle } from '@sleekly-ui/components/ScreenTitle'
import { List } from '@sleekly-ui/components/List'
import { SearchInput } from '@sleekly-ui/components/SearchInput'


type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const CompanySearchPage: React.FC<Props> = ({ }: Props) => {

    const [searchHistory] = AppState.useState<SearchHistory[]>(Workflow.SEARCH_HISTORY, []);

    const [candidateCompanies, setCandidateCompanies] = React.useState<CompanyOverview[]>([]);

    const [searchProgressIndicatorVisible, setSearchProgressIndicatorVisible] = React.useState(false);

    const [query, setQuery] = React.useState<string>('');

    const [keywordsSuggestions, setKeywordsSuggestions] = React.useState<string[]>([]);

    const [selectedCompany, setSelectedCompany] = React.useState<CompanyOverview>(null);

    React.useEffect(() => {

    }, []);

    const executeSearch = async (query: string) => {
        setQuery(query);
        setKeywordsSuggestions([]);
        setCandidateCompanies([]);
        setSearchProgressIndicatorVisible(true);

        Workflow.addSearchHistory({
            id: moment().toISOString(),
            query,
            date: moment().toISOString(),
            count: 1
        });

        const searchQuery = `${query}`.trim();
        try {
            const searchResults = await WebSearch.webSearch(searchQuery, "linkedin.com/company");

            const companies: CompanyOverview[] = searchResults.results.map((res, index) => {
                const name = res.title.replace(' | LinkedIn', '')
                    .replace(' - LinkedIn', '');
                return {
                    id: `${name}-${index}`,
                    name,
                    description: res.content,
                    url: res.url
                }
            });

            const visibleCompanies = Workflow.filterDiscardedCompanies(companies);
            const keywords = await AI.suggestCompaniesKeywords(query, visibleCompanies, 5);
            setKeywordsSuggestions(keywords);

            setCandidateCompanies(visibleCompanies);
            setSearchProgressIndicatorVisible(false);
        } catch (e) {
            logOut();
        }
    }



    const captureEnter = (event) => {
        if (event.key === "Enter") {
            // Cancel the default action, if needed
            event.preventDefault();
            executeSearch(query);
        }
    }

    const companyFocusModeColumnStyle = selectedCompany ? 'hidden' : '';
    const companyFocusModeDetailsStyle = selectedCompany ? '' : 'hidden';
    const companyFocusModeListStyle = selectedCompany ? 'max-w-md' : '';

    const visibleSearchHistory = searchHistory
        .sort((a, b) => b.date.localeCompare(a.date))
        .slice(0, Math.min(searchHistory.length, 5));

    return <>
        <ScreenTitle>Recherche d'entreprises</ScreenTitle>
        <ColumnPageLayout>
            <SideColumn className={companyFocusModeColumnStyle}>
                <ScreenTitle level='secondary'>Historique de recherche</ScreenTitle>
                <List items={visibleSearchHistory}
                    renderItem={(item: SearchHistory) => {
                        return <div key={item.query}
                            className='ml-2 py-1 px-2 border-l-2 my-2 hover:text-primary hover:border-primary cursor-pointer text-sm'
                            onClick={() => executeSearch(item.query)}>
                            {item.query}
                        </div>
                    }}
                    emptyMessage={'Pas de recherches récentes'}
                    emptyMessageDetails={'Vos recherches précédentes s\'afficheront ici'}>
                </List>
                <div className='h-4'></div>
                <ScreenTitle level='secondary'>Filtres sur les résultats de recherche</ScreenTitle>
                <SearchFiltersWidget></SearchFiltersWidget>

            </SideColumn>
            <CenterColumn className={`${companyFocusModeListStyle}`}>
                <ScreenTitle level='secondary'>Recherche</ScreenTitle>
                <SearchInput query={query} onPerformQuery={executeSearch}
                    placeholder='Nom, domaine, activité, Mots clés...'></SearchInput>
                {
                    keywordsSuggestions.length > 0 && <div className='p-2'>
                        <div className='flex flex-wrap mb-1'>
                            {
                                keywordsSuggestions
                                    .slice(0, Math.min(keywordsSuggestions.length, 10)).map(keyword => {

                                        return <div key={keyword}>

                                            <div className='badge cursor-pointer hover:text-accent badge-outline mx-1'
                                                onClick={() => executeSearch(query + " " + keyword)}>{keyword}</div>
                                        </div>
                                    })
                            }
                        </div>
                    </div>
                }
                {
                    candidateCompanies.length > 0 && <>
                        <div className='flex items-center mt-4 mb-1 '>
                            <div className='text-xs'>Résultats ({candidateCompanies.length})</div>
                            <div className='flex-1'></div>
                            <Tooltip text='Affichage en mode tableau' tooltipOrientation='left'>
                                <button className='btn btn-xs btn-ghost btn-circle'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                        className='h-4 w-4'><path d="M3 3C2.44772 3 2 3.44772 2 4V20C2 20.5523 2.44772 21 3 21H21C21.5523 21 22 20.5523 22 20V4C22 3.44772 21.5523 3 21 3H3ZM8 5V8H4V5H8ZM4 14V10H8V14H4ZM4 16H8V19H4V16ZM10 16H20V19H10V16ZM20 14H10V10H20V14ZM20 5V8H10V5H20Z"></path></svg>
                                </button>
                            </Tooltip>
                        </div>


                    </>
                }
                <div className='h-2'></div>
                <CandidateCompaniesList
                    loading={searchProgressIndicatorVisible}
                    companies={candidateCompanies}
                    onItemSelectionChange={setSelectedCompany}
                    query={query}></CandidateCompaniesList>


            </CenterColumn>
            <CenterColumn className={`${companyFocusModeDetailsStyle}`}>
                {
                    selectedCompany && <CompanyDetailsWidget company={selectedCompany}
                        onCloseFocus={() => setSelectedCompany(null)}></CompanyDetailsWidget>
                }
            </CenterColumn>
            {/* <SideColumn >
                <SelectedCompaniesWidget compact={true}></SelectedCompaniesWidget>
            </SideColumn> */}
        </ColumnPageLayout>
    </>
}