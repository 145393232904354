import * as React from 'react'
import { ZonePlaceholder } from '@sleekly-ui/components/ZonePlaceholder'
import { ScreenTitle } from '@sleekly-ui/components/ScreenTitle'
import { SearchInput } from '@sleekly-ui/components/SearchInput'
import { WebSearch } from '../../core/WebSearch'
import { MarketReport } from '@xplorer/MarketReport'
import { List } from '@sleekly-ui/components/List'
import { MarketReportsSearch } from './MarketReportsSearch'
import { TechnologyDocuments } from './TechnologyDocuments'
import { AppState } from '@sleekly-ui/AppState'
import { MarketAnalysisDialog } from './MarketAnalysisDialog'

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const Technology: React.FC<Props> = ({ }: Props) => {

    const [documents, setDocuments] = AppState.useState<MarketReport[]>('collected-market-reports', []);

    const collectDocument = (document: MarketReport) => {
        const update = [].concat(documents, document);
        setDocuments(update);
    }
   
    return <>
        <ScreenTitle>Contexte de la technologie</ScreenTitle>
        <div className='flex-1 flex space-x-4 mt-2 overflow-hidden'>
            {/* <input className='input input-bordered' placeholder='Mots clés décrivant la technologie'></input> */}
            <div className='flex flex-col overflow-hidden w-4/12'>
                <TechnologyDocuments></TechnologyDocuments>
            </div>
            <MarketReportsSearch onCollectDocument={collectDocument}></MarketReportsSearch>
        </div>
        <MarketAnalysisDialog></MarketAnalysisDialog>
    </>
}