import * as React from 'react'
import { DomainSummary, Workflow } from '../core/Workflow';
import { SectionLabel } from '../ext/Layout';

import { DomainSummaryCard } from './DomainSummaryCard';
import { List } from '@sleekly-ui/components/List';
import { ScreenTitle } from '@sleekly-ui/components/ScreenTitle';

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const DomainsSummaryWidget: React.FC<Props> = ({ }: Props) => {

    const domainSummaries = Workflow.useDomainSumaries();

    return <div className='flex flex-1 flex-col overflow-hidden'>
        <ScreenTitle level='secondary'>Domaines d'application</ScreenTitle>

        <List items={domainSummaries}
            renderItem={(domain: DomainSummary) => {
                return <DomainSummaryCard key={domain.label} domain={domain} ></DomainSummaryCard>
            }}
            emptyMessage={'Aucun Domaine sélectionné'}
            emptyMessageDetails={'Les domaines sélectionnées pour le projet s\'afficheront ici.'}
            >
        </List>
    </div>
}