import * as React from 'react'
import { PinControls, PinControlsAttributes } from './PinControls'

import { CompanyOverview } from '../core/Workflow'

import { ScoreIndicator } from './ScoreIndicator'

import { CompanyInfoBadges } from './CompanyInfoBadges'

import { Tooltip } from '@sleekly-ui/components/Tooltip'
import { DataBadge } from '@sleekly-ui/components/DataBadge'
import { CommentIcon } from '@sleekly-ui/icons/CommentIcon'
import { CompanyIcon } from '@sleekly-ui/icons/CompanyIcon'
import { GoogleIcon } from '@sleekly-ui/icons/GoogleIcon'
import { LinkedInIcon } from '@sleekly-ui/icons/LinkedInIcon'
import { WebIcon } from '@sleekly-ui/icons/WebIcon'

type Props = {
    company: CompanyOverview,
    compact?: boolean,
    selected?: boolean,
    onOpenDetails?: (company: CompanyOverview) => void
    onSelectItem?: (company: CompanyOverview) => void
} & React.HTMLAttributes<HTMLDivElement> & PinControlsAttributes

export const CandidateCompanyCard: React.FC<Props> = ({ company,
    selected,
    compact,
    isPinned,
    onDelete,
    onPin,
    onOpenDetails,
    onSelectItem }: Props) => {

    const openDetails = () => {
        onOpenDetails && onOpenDetails(company);
    }

    const selectedStyle = selected ? 'border border-primary' : '';
    return <div
        className={`shadow-md bg-base-200 rounded-md p-2 my-1 ${selectedStyle}`}
        >
        <div className='flex mb-1 space-x-2 '>
            <Tooltip text='Afficher la fiche entreprise' tooltipOrientation='right'>
                <div className='flex space-x-2 hover:text-primary cursor-pointer'
                    onClick={openDetails}>
                    <span className='mt-1'>
                        <CompanyIcon></CompanyIcon>
                    </span>
                    <div className='font-bold'>
                        <span>{company.name}</span>
                    </div>
                </div>
            </Tooltip>
            <div className='flex-1'></div>

            <div className='flex space-x-1 pr-4'>
                <a className='btn btn-circle btn-xs btn-ghost'
                    target='blank'
                    href={`https://www.google.com/search?q=${company.name}&btnI=1`}>
                    <WebIcon></WebIcon>
                </a>

                <a className='btn btn-circle btn-xs btn-ghost'
                    target='blank'
                    href={`https://www.google.com/search?q=${company.name}`}>
                    <GoogleIcon></GoogleIcon>
                </a>
                <a className='btn btn-circle btn-xs btn-ghost'
                    target='blank'
                    href={company.url}>
                    <LinkedInIcon></LinkedInIcon>
                </a>
            </div>
            {
                !compact && <div className='mt-0'>
                    <div className="tooltip tooltip-left" data-tip="Score de pertinence 57%">
                        <ScoreIndicator scorePercent={57}></ScoreIndicator>
                    </div>
                </div>
            }


            <PinControls
                data={company}
                isPinned={isPinned}
                onPin={onPin}
                onDelete={onDelete}
            ></PinControls>
        </div>

        {
            !compact && <>
                <div className='text-sm line-clamp-3'>{company.description}</div>
                <div className='mt-2 flex items-center'>

                    <CompanyInfoBadges></CompanyInfoBadges>

                    <div className='flex-1 '></div>

                    <DataBadge entries={[
                        {
                            icon: <CommentIcon></CommentIcon>,
                            value: '5',
                            tooltip: 'Commentaires'
                        }
                    ]}></DataBadge>
                </div>
            </>
        }

    </div>
}