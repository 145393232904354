import * as React from 'react'
import * as moment from 'moment'

import { CompanyOverview } from '../../core/Workflow'
import { ProgressIndicator } from '@sleekly-ui/components/ProgressIndicator'
import { UserAvatar } from '@sleekly-ui/components/UserAvatar'

export type CompanyComment = {
    id: string,
    content: string,
    date: string,
    author: string
}

type Props = {
    company: CompanyOverview
} & React.HTMLAttributes<HTMLDivElement>

export const CompanyComments: React.FC<Props> = ({ company }: Props) => {

    const [comments, setComments] = React.useState<CompanyComment[]>([]);

    const [loading, setLoading] = React.useState(false);

    const [commentInput, setCommentInput] = React.useState<string>('');

    React.useEffect(() => {
        if (company) {
            fetchComments(company);
        }
    }, [company]);


    const fetchComments = async (company: CompanyOverview) => {
        setComments([]);
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setComments([
                {
                    id: "1",
                    content: "Innovatech Solutions se distingue par ses solutions technologiques innovantes et sur mesure. Leur équipe d’experts maîtrise les dernières technologies, garantissant des solutions efficaces et avant-gardistes. Leur engagement envers la satisfaction client et la collaboration étroite avec leurs partenaires en fait un acteur clé de la transformation numérique. Innovatech Solutions est un partenaire idéal pour toute entreprise cherchant à innover et se développer.",
                    date: moment().toISOString(),
                    author: "John Doe"
                },
                {
                    id: "2",
                    content: "Innovatech Solutions se distingue par ses solutions technologiques innovantes et sur mesure. Leur équipe d’experts maîtrise les dernières technologies, garantissant des solutions efficaces et avant-gardistes. Leur engagement envers la satisfaction client et la collaboration étroite avec leurs partenaires en fait un acteur clé de la transformation numérique. Innovatech Solutions est un partenaire idéal pour toute entreprise cherchant à innover et se développer.",
                    date: moment().toISOString(),
                    author: "John Doe"
                },
                {
                    id: "3",
                    content: "Innovatech Solutions se distingue par ses solutions technologiques innovantes et sur mesure. Leur équipe d’experts maîtrise les dernières technologies, garantissant des solutions efficaces et avant-gardistes. Leur engagement envers la satisfaction client et la collaboration étroite avec leurs partenaires en fait un acteur clé de la transformation numérique. Innovatech Solutions est un partenaire idéal pour toute entreprise cherchant à innover et se développer.",
                    date: moment().toISOString(),
                    author: "John Doe"
                },
                {
                    id: "3",
                    content: "Innovatech Solutions se distingue par ses solutions technologiques innovantes et sur mesure. Leur équipe d’experts maîtrise les dernières technologies, garantissant des solutions efficaces et avant-gardistes. Leur engagement envers la satisfaction client et la collaboration étroite avec leurs partenaires en fait un acteur clé de la transformation numérique. Innovatech Solutions est un partenaire idéal pour toute entreprise cherchant à innover et se développer.",
                    date: moment().toISOString(),
                    author: "User"
                }
            ]);

            scrollToEnd();
        }, 500);

    }

    const endDivRef = React.useRef(null);

    const scrollToEnd = () => {
        setTimeout(() => {
            endDivRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "end"
            });
        }, 100);
    };

    const saveComment = () => {
        const newComment = {
            id: moment().toISOString(),
            content: commentInput,
            date: moment().toISOString(),
            author: "User"
        };
        const update = [].concat(comments, newComment);
        setCommentInput('');
        setComments(update);
        scrollToEnd();
    }

    const getUserLetters = (userName: string) => {
        if(userName === "User") {
            return "U"
        }else {
            return "JD"
        }
    }

    return <div className='flex flex-col flex-1 overflow-hidden'>
        <h1 className='p-2 text-md font-bold'>Commentaires ({comments.length})</h1>
        <ProgressIndicator visible={loading}></ProgressIndicator>
        <div className='p-2 flex flex-col flex-1 overflow-auto'>
            {
                comments.map(item => {
                    const date = item.date ? moment(item.date).format('DD/MM/YYYY') : 'Date inconnue';
                    const userMessage = item.author === "User";
                    const emmiterStyle = userMessage ? 'chat-end' : 'chat-start';
                    return <div key={item.id}
                        className={`chat ${emmiterStyle}`}>
                        <div className='chat-image avatar'>
                        <UserAvatar userName={getUserLetters(item.author)}></UserAvatar>
                        </div>

                        <div className="chat-header">
                            <div className='flex items-center space-x-2'>
                                <div className='font-bold text-xs flex-1'>{item.author}</div>
                                <time className="text-xs opacity-50 row-start-12">{date}</time>
                            </div>
                        </div>

                        <div className='chat-bubble bg-base-200 text-base-content'>
                            <div className='text-sm'>{item.content}</div>
                        </div>
                    </div>
                })}
            <div ref={endDivRef} className='w-full h-4'></div>
        </div>
        <div className='p-2 border rounded-md'>
            <textarea className='textarea w-full focus:outline-none focus:border-none'
                value={commentInput}
                onChange={(e) => setCommentInput(e.target.value)}></textarea>
            <div className='flex justify-end'>
                <button className='btn  btn-sm '
                    onClick={saveComment}
                    disabled={commentInput.length === 0}>
                    <span>Enregistrer</span>
                </button>
            </div>
        </div>
    </div>
}