import * as React from 'react'

import { PinnedCompany, Workflow } from '../core/Workflow';
import { SectionLabel } from '../ext/Layout';


import { CompanyTile } from './CompanyTile';

import { List } from '@sleekly-ui/components/List';

import { AppState } from '@sleekly-ui/AppState';
import { ExpandIcon } from '@sleekly-ui/icons/ExpandIcon';
import { CompanyIcon } from '@sleekly-ui/icons/CompanyIcon';
import { ScreenTitle } from '@sleekly-ui/components/ScreenTitle';

type Props = {
    compact?: boolean,
    hideShortcut?: boolean,
    selectedCompany?: PinnedCompany
    onSelectCompany?: (company: PinnedCompany) => void
} & React.HTMLAttributes<HTMLDivElement>

export const SelectedCompaniesWidget: React.FC<Props> = ({ selectedCompany, onSelectCompany, hideShortcut, compact }: Props) => {

    const [pinnedCompanies] = AppState.useState<PinnedCompany[]>(Workflow.PINNED_COMPANIES, []);

    const onExpand = () => {
        AppState.pushState('app-activity', 'prospect');
    }

    const companyTileRenderer = (selectedCompany, onSelectCompany) => (company: PinnedCompany) => {
        const selected = selectedCompany?.name === company.name;
        return <CompanyTile company={company}
            compact={true}
            selected={selected} onSelectCompany={onSelectCompany}></CompanyTile>
    }

    return <div className='flex-1 flex flex-col overflow-hidden'>
        <ScreenTitle level='secondary'>
            <span>Entreprises sélectionnées ({pinnedCompanies.length})</span>
            <div className='flex-1'></div>
            {
                !hideShortcut && <button className='btn btn-ghost btn-xs btn-circle'
                    onClick={onExpand}>
                    <ExpandIcon></ExpandIcon>
                </button>
            }
        </ScreenTitle>

        <List items={pinnedCompanies}
            renderItem={companyTileRenderer(selectedCompany, onSelectCompany)}
            emptyMessage={'Aucune Entreprise sélectionnée'}
            emptyMessageDetails={'Les entreprises sélectionnées pour le projet s\'afficheront ici.'}
            emptyIllustration={() => {
                return <CompanyIcon></CompanyIcon>
            }}>
        </List>
    </div>
}