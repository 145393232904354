import { ServerApi } from "@sleekly-ui/ServerApi";
import { getCurrentUser, logOut } from "../login/Login";

export interface OAIMessage {
    role: string,
    content: string
}

export namespace OpenAI {

    export async function autocomplete(messages: OAIMessage[], format?: any): Promise<String> {
        const user = getCurrentUser();
        const response = await ServerApi.postJson("/api/oai_autocomplete", {
            messages,
            format: JSON.stringify(format)
        }, user?.token);
        if (response.status === 200) {
            return JSON.parse(`${response.data}`);
        } else if (response.status === 403) {
            logOut();
        } else {
            console.log(response.status, response.statusText);
        }
    }
}