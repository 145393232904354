import * as React from 'react'

type Props = {
    placeholder: string;
    onAddItem: (text: string) => void;
    actionIcon: React.ReactElement
} & React.HTMLAttributes<HTMLDivElement>

export const ItemAddInput: React.FC<Props> = ({ placeholder, onAddItem, actionIcon }: Props) => {

    const [text, setText] = React.useState('');

    const captureEnter = (event) => {
        if (event.key === "Enter") {
            // Cancel the default action, if needed
            event.preventDefault();

            onAddItem(text);
            setText('')
        }
    }

    return <div className='mb-1'>
        <div className='flex space-x-2 p-1'>
            <input className='input input-sm input-bordered w-full'
                placeholder={placeholder}
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyDown={captureEnter}></input>

            {
                text.length > 0 && <button className='btn btn-sm btn-square btn-accent'
                    onClick={() => onAddItem(text)}>
                    {actionIcon}
                </button>
            }

        </div>
    </div>
}