import * as React from 'react'

import { Domain, DomainHistory, Workflow } from '../../core/Workflow'
import { SelectedCompaniesWidget } from '../../components/SelectedCompaniesWidget'
import { DomainsSummaryWidget } from '../../components/DomainsSummaryWidget'

import { DomainHistoryCard } from './DomainHistoryCard'
import { List } from '@sleekly-ui/components/List'
import { ScreenTitle } from '@sleekly-ui/components/ScreenTitle'

type Props = {
    onSelectItem?: (domain: Domain, keyword: string) => void
} & React.HTMLAttributes<HTMLDivElement>

export const ProjectSummary: React.FC<Props> = ({ onSelectItem }: Props) => {

    const domainHistory = Workflow.useDomainHistory()

    domainHistory.sort((a, b) => b.date.localeCompare(a.date));

    return <>
        <ScreenTitle>Résumé Projet</ScreenTitle>
        <div className='flex-1 flex space-x-4 mt-2 overflow-hidden'>
            <div className='w-full flex flex-col overflow-hidden max-w-md'>
                <SelectedCompaniesWidget hideShortcut={true}
                    compact={true}></SelectedCompaniesWidget>
                <div className='h-4'></div>
                <DomainsSummaryWidget></DomainsSummaryWidget>
            </div>
            <div className='w-full flex flex-col overflow-hidden'>
                <ScreenTitle level='secondary'>Exploration récentes</ScreenTitle>
                <List items={domainHistory}
                    renderItem={(item: DomainHistory) => {
                        return <DomainHistoryCard item={item}></DomainHistoryCard>
                    }}
                    emptyMessage={'Aucune Exploration récente'}
                    emptyMessageDetails={'Commencez par explorer des domaines ou rechercher des entreprises'}
                // emptyIllustration={() => {
                //     return <CompanyIcon></CompanyIcon>
                // }}
                >
                </List>
            </div>
        </div>
    </>
}