import * as React from 'react'
import { ItemAddInput } from './ItemAddInput'
import { SearchIcon } from '@sleekly-ui/icons/SearchIcon'

export type Keyword = {
    text: string,
    explored: boolean,
    selected: boolean
}

type Props = {
    keywords: Keyword[];
    onSelectKeyword: (keyword: string) => void;
    onAddKeyword: (keyword: string) => void
} & React.HTMLAttributes<HTMLDivElement>

export const KeywordsList: React.FC<Props> = ({ keywords, onSelectKeyword, onAddKeyword }: Props) => {

    keywords.sort((a,b) => a.text.localeCompare(b.text));
    return <div>
        <ItemAddInput placeholder='Recherche activité précise'
            onAddItem={onAddKeyword}
            actionIcon={<SearchIcon></SearchIcon>}></ItemAddInput>
        {
            keywords.map(keyword => {
                const exploredStyleValue = 'opacity-100';
                const unexploredStyleValue = 'opacity-50';


                const exploredStyle = keyword.explored ? exploredStyleValue : unexploredStyleValue;
                const style = keyword.selected ? 'text-primary' : exploredStyle;
                return <div key={keyword.text}
                    className={`pl-1 cursor-pointer text-sm ${style}`}
                    onClick={() => onSelectKeyword(keyword.text)}>
                    <span>{keyword.text}</span>
                </div>
            })
        }
    </div>
}