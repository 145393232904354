import * as React from "react";

export function usePreviousState<T>(value: T) {
    const ref = React.useRef<T>(null);
    React.useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current; //in the end, return the current ref value.
}

export function useTest() {
    return React.useState(null);
}