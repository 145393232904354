import * as React from 'react'
import { AppState } from '../AppState';

type Props = {
    dialogId: string,
    renderContent: (data: any, close: () => void) => void,
    title?: string,
    closeButtonHidden?: boolean
} & React.HTMLAttributes<HTMLDivElement>

export const Dialog: React.FC<Props> = ({ dialogId, title, closeButtonHidden, className, renderContent }: Props) => {

    const [value, setValue] = AppState.useState(`dialog-data-${dialogId}`);

    React.useEffect(() => {
        if (value) {
            (document.getElementById(dialogId) as any).showModal()
        } else {
            (document.getElementById(dialogId) as any).close()
        }
    }, [value, dialogId]);

    const close = () => {
        closeDialog(dialogId);
    }

    return <dialog id={dialogId} className="modal focus:outline-none">

        <div className={`modal-box w-6/12 max-w-5xl flex flex-col overflow-hidden ${className}`}>
            {
                title && <>
                    <div className='flex items-center'>
                        <h3 className='font-bold text-lg flex-1 '>{title}</h3>
                        {
                            !closeButtonHidden && <form method="dialog">
                                <button className="btn btn-sm btn-circle btn-ghost focus:outline-none">✕</button>
                            </form>
                        }
                    </div>
                </>
            }
            {
                renderContent && value && <>
                    {renderContent(value, close)}
                </>
            }
        </div>
    </dialog>
}

export function openDialog(dialogId: string, data: any) {
    AppState.pushState(`dialog-data-${dialogId}`, data)
}

export function closeDialog(dialogId: string) {
    AppState.pushState(`dialog-data-${dialogId}`, null)
}