import * as React from 'react'
import { CompanyOverview } from '../../core/Workflow'

import { GeneralInfo } from './GeneralInfo'
import { CompanyPatents } from './CompanyPatents'
import { CompanyNews } from './CompanyNews'
import { CompanyComments } from './CompanyComments'

import { CloseIcon } from '@sleekly-ui/icons/CloseIcon'
import { PageSwitcher, PageSwitcherItem } from '@sleekly-ui/layout/PageSwitcher'
import { usePreviousState } from '@sleekly-ui/UsePreviousState'

type Props = {
    company: CompanyOverview,
    onCloseFocus: () => void
} & React.HTMLAttributes<HTMLDivElement>

export const CompanyDetailsWidget: React.FC<Props> = ({ company, onCloseFocus }: Props) => {
    
    

    const views: PageSwitcherItem[] = [
        {
            id: 'general',
            title: 'Général',
            render: (company) => <GeneralInfo company={company}></GeneralInfo>
        },
        {
            id: 'patents',
            title: 'Brevets',
            render: (company) => <CompanyPatents company={company}></CompanyPatents>
        },
        {
            id: 'news',
            title: 'Actualités',
            render: (company) => <CompanyNews company={company}></CompanyNews>
        },
        {
            id: 'comments',
            title: 'Commentaires',
            render: (company) => <CompanyComments company={company}></CompanyComments>
        }
    
    ]

    const [activeTab, setActiveTab] = React.useState<string>(views[0].id);

    const prevCompany = usePreviousState(company);
    React.useEffect(() => {
        if(prevCompany?.name !== company.name) {
            setActiveTab(views[0].id)
        }
    }, [company]);
    
    return <div className='flex-1 flex flex-col overflow-hidden'>
        <div className='flex'>
            <h1 className='font-bold text-xl'>{company.name}</h1>
            <div className='flex-1'></div>
            <button className='btn btn-sm btn-circle'
                onClick={onCloseFocus && onCloseFocus}>
                <CloseIcon></CloseIcon>
            </button>
        </div>
        <div role="tablist" className="tabs tabs-bordered">
            {
                views.map(v => {
                    const activeStyle = v.id === activeTab ? 'tab-active' : '';
                    return <a role="tab" className={`tab ${activeStyle}`}
                    onClick={() => setActiveTab(v.id)}>{v.title}</a>
                })
            }
        </div>
        <PageSwitcher activePageId={activeTab} pages={views} pageProps={company}>

        </PageSwitcher>
    </div>
}