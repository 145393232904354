import * as React from 'react'
import { CompanyOverview, PinnedCompany, SearchContext, Workflow } from '../core/Workflow'
import { CandidateCompanyCard } from './CandidateCompanyCard'

import { CompanyDetailsDialog } from './CompanyDetailsDialog'

import { AppState } from '@sleekly-ui/AppState'
import { List } from '@sleekly-ui/components/List'

type Props = {
    loading?: boolean,
    searchContext?: SearchContext,
    query?: string,
    companies: CompanyOverview[],
    onItemSelectionChange: (item: CompanyOverview) => void
} & React.HTMLAttributes<HTMLDivElement>

export const CandidateCompaniesList: React.FC<Props> = ({
    loading,
    companies,
    searchContext, query,
    onItemSelectionChange
}: Props) => {

    const [pinnedCompanies] = AppState.useState(Workflow.PINNED_COMPANIES, []);

    const [discardedCompanies, setDiscardedCompanies] = AppState.useState(Workflow.DISCARDED_COMPANIES, []);

    const [selectedCompany, setSelectedCompany] = React.useState<CompanyOverview>(null);

    React.useEffect(() => {
        onItemSelectionChange && onItemSelectionChange(selectedCompany);
    }, [selectedCompany]);

    const pinCompany = (company: CompanyOverview) => {
        Workflow.addPinnedCompanies(company, searchContext, query)
    }

    const unpinCompany = (company: PinnedCompany) => {
        Workflow.removePinnedCompany(company)
    }

    const deleteCompany = (company: CompanyOverview) => {
        const update = [].concat(discardedCompanies, company);
        setDiscardedCompanies(update);
    }

    const showDetails = (company: CompanyOverview) => {
        setSelectedCompany(company);
        // openDialog('company-details');
    }

    const toggleCompanySelection = (company: CompanyOverview) => {
        if (company.name === selectedCompany?.name) {
            setSelectedCompany(null);
        } else {
            setSelectedCompany(company);
        }
    }

    const companyTileRenderer = (pinnedCompanies, selectedCompany, toggleCompanySelection, pinCompany, unpinCompany, showDetails) => (company: CompanyOverview) => {
        const isPinned = pinnedCompanies.find(d => d.name === company.name);
        const selected = selectedCompany?.name === company.name;
        return <CandidateCompanyCard company={company}
            isPinned={isPinned}
            selected={selected}
            onSelectItem={toggleCompanySelection}
            onPin={pinCompany}
            onRemovePin={unpinCompany}
            // onDelete={deleteCompany}
            onOpenDetails={showDetails}
        ></CandidateCompanyCard>
    };

    return <List
        items={companies}
        loading={loading}
        renderItem={companyTileRenderer(pinnedCompanies, selectedCompany, toggleCompanySelection, pinCompany, unpinCompany, showDetails)}
        emptyMessage={'Pas de résultat de recherche'}
        emptyMessageDetails={'Les résultats de recherche s\'afficheront ici'}>
    </List>
}