import * as React from 'react'

type Props = {
    userName: string
} & React.HTMLAttributes<HTMLDivElement>

export const UserAvatar: React.FC<Props> = ({userName }: Props) => {

    const initials = getInitials(userName);
    return <div className="avatar placeholder">
        <div className="bg-neutral text-neutral-content w-8 rounded-full">
            <span className="text-xs">{initials}</span>
        </div>
    </div>
}

function getInitials(fullName) {
    if (typeof fullName !== 'string' || fullName.trim().length === 0) {
        return 'U';
    }

    const names = fullName.trim().split(' ');

    if (names.length === 1) {
        return names[0].slice(0, 2).toUpperCase();
    }

    const firstNameInitial = names[0][0].toUpperCase();
    const lastNameInitial = names[names.length - 1][0].toUpperCase();

    return firstNameInitial + lastNameInitial;
}