import { AppState } from '@sleekly-ui/AppState'
import { List } from '@sleekly-ui/components/List'
import { ScreenTitle } from '@sleekly-ui/components/ScreenTitle'
import { AddIcon } from '@sleekly-ui/icons/AddIcon'
import { MagicIcon } from '@sleekly-ui/icons/MagicIcon'
import { WebIcon } from '@sleekly-ui/icons/WebIcon'
import { MarketReport } from '@xplorer/MarketReport'
import * as React from 'react'
import { WebSearch } from '../../core/WebSearch'

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const TechnologyDocuments: React.FC<Props> = ({ }: Props) => {

    const [documents, setDocuments] = AppState.useState<MarketReport[]>('collected-market-reports', []);

    const analyzeDocument =  (document: MarketReport) => async () => {
        const report = await WebSearch.analyzeMarketReport(document);
        console.log(report);
        
    }

    const renderDocument = (analyzeDocument) => (document: MarketReport) => {
        return <div className='flex space-x-2 p-2'>
            <div className='flex items-center pr-2'>
                <WebIcon className='h-6 w-6'></WebIcon>
            </div>
            <div className='flex-1'>
                <a className='font-bold text-sm line-clamp-1 hover:text-primary' href={document.url} target='_blank'>{document.title}</a>
                <div className='text-xs opacity-75'>{document.url}</div>
            </div>
            <button className='btn btn btn-square btn-xs' onClick={analyzeDocument(document)}>
                <MagicIcon></MagicIcon>
            </button>
        </div>
    }
    return <div className='flex-1 flex flex-col overflow-hidden'>
        <ScreenTitle level='secondary'>
            <span className='flex-1'>Documents Attachés</span>
            <button className='btn btn-xs'>
                <AddIcon></AddIcon>
                <span>PDF</span>
            </button>
        </ScreenTitle>
        <List
            items={documents}
            renderItem={renderDocument(analyzeDocument)}></List>
    </div>
}