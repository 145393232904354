import * as React from 'react'
import { ScreenTitle } from '@sleekly-ui/components/ScreenTitle'
import { SearchInput } from '@sleekly-ui/components/SearchInput'
import { WebSearch } from '../../core/WebSearch'
import { MarketReport } from '@xplorer/MarketReport'
import { List } from '@sleekly-ui/components/List'
import { AddIcon } from '@sleekly-ui/icons/AddIcon'
import { DeleteIcon } from '@sleekly-ui/icons/DeleteIcon'
import { MagicIcon } from '@sleekly-ui/icons/MagicIcon'
import { openDialog } from '@sleekly-ui/layout/Dialog'


type Props = {
    onCollectDocument: (report: MarketReport) => void
} & React.HTMLAttributes<HTMLDivElement>

export const MarketReportsSearch: React.FC<Props> = ({ onCollectDocument }: Props) => {

    const [query, setQuery] = React.useState<string>('');

    const [reports, setReports] = React.useState<MarketReport[]>([]);

    const [loading, setLoading] = React.useState(false);

    const searchReports = async (query) => {
        setLoading(true);
        const reports = await WebSearch.searchMarketReports(query);
        setLoading(false);
        setReports(reports);
    }

    const collectDocument = (report: MarketReport) => {
        onCollectDocument && onCollectDocument(report);
        const update = reports.filter(r => r.id !== report.id);
        setReports(update);
    }

    const discardDocument = (report: MarketReport) => {
        const update = reports.filter(r => r.id !== report.id);
        setReports(update);
    }

    const analyzeDocument =  (document: MarketReport) => async () => {
        openDialog('market-analysis', document);
    }


    const renderReport = (collectDocument, discardDocument, analyzeDocument) => (report: MarketReport) => {
        return <div className='p-2 flex items-center space-x-2'>
            <div className='flex-1'>
                <a className='font-bold line-clamp-1 hover:text-primary' href={report.url} target='_blank'>{report.title}</a>
                <div className='text-sm line-clamp-3'>{report.summary}</div>
            </div>
            <button className='btn btn-sm hover:btn-accent btn-square' onClick={analyzeDocument(report)}>
                <MagicIcon></MagicIcon>
            </button>
            <div className='flex flex-col space-y-2'>

                <button className='btn btn-circle btn-xs'
                    onClick={() => collectDocument(report)}>
                    <AddIcon></AddIcon>
                </button>
                <button className='btn btn-circle btn-xs'
                    onClick={() => discardDocument(report)}>
                    <DeleteIcon></DeleteIcon>
                </button>
            </div>
        </div>
    }


    return <>

        <div className='flex-1 flex flex-col overflow-hidden'>
            <ScreenTitle level='secondary'>Recherche d'études de marché</ScreenTitle>

            <div>
                <SearchInput className='max-w-prose my-2'
                    placeholder="Mots clés décrivant la technologie" query={query} onPerformQuery={searchReports}></SearchInput>
            </div>

            <List
                className='m-0'
                items={reports}
                loading={loading}
                emptyMessage="Aucune recherche en cours"
                emptyMessageDetails="Vos Résultats de recherche s'afficheront ici"
                renderItem={renderReport(collectDocument, discardDocument, analyzeDocument)}>

            </List>
        </div>
    </>
}