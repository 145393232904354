import * as React from 'react'
import { Domain, DomainSummary, Workflow } from '../core/Workflow';
import { DomainTreeNode } from './DomainTreeNode';
import { AI } from '../core/AI';
import { ItemAddInput } from './ItemAddInput';

import { AppState } from '@sleekly-ui/AppState';
import { ProgressIndicator } from '@sleekly-ui/components/ProgressIndicator';
import { SearchIcon } from '@sleekly-ui/icons/SearchIcon';

import { SectionLabel } from '../ext/Layout';
import { ScreenTitle } from '@sleekly-ui/components/ScreenTitle';
import { List } from '@sleekly-ui/components/List';

type Props = {
    addDisabled?: boolean
    onSelectItem: (domain: Domain, keyword?: string) => void
} & React.HTMLAttributes<HTMLDivElement>

export const DomainsOverviewWidget: React.FC<Props> = ({ addDisabled, onSelectItem }: Props) => {

    const domainSummaries = Workflow.useDomainSumaries();

    const [currentProject] = AppState.useState<any>('current-project');

    const [inProgress, setInProgress] = React.useState(false);

    const addNewDomain = async (label: string) => {
        setInProgress(true);
        let domain = await AI.createDomainDetails(currentProject.description, label);
        domain.id = label;
        domain.label = label;
        Workflow.addDomain(domain);
        setInProgress(false);
        onSelectItem(domain);
    }

    const domainCardRenderer = (onSelectItem) => (domain: DomainSummary) => {
        return <DomainTreeNode key={domain.label}
            domain={domain}
            onOpenSearchContext={onSelectItem ? onSelectItem : () => { }}>
        </DomainTreeNode>
    }

    return <div className='flex flex-1 flex-col overflow-hidden'>
        <ScreenTitle level='secondary'>Domaines d'application</ScreenTitle>

        {
            !addDisabled && <ItemAddInput placeholder='Rechercher dans un domaine précis'
                onAddItem={addNewDomain}
                actionIcon={<SearchIcon></SearchIcon>}></ItemAddInput>
        }


        <ProgressIndicator visible={inProgress}></ProgressIndicator>

        <List items={domainSummaries}
            renderItem={domainCardRenderer(onSelectItem)}
            emptyMessage={'Aucun Domaine épinglé'}
            emptyMessageDetails={'Commencez par explorer des domaines'}
        // emptyIllustration={() => {
        //     return <CompanyIcon></CompanyIcon>
        // }}
        >
        </List>

    </div>
}