
export interface ServerResponse<T> {
    status: number,
    statusText?: string,
    data?: T
}

export namespace ServerApi {

    export async function postJson<T>(endpoint: string, payload: any, auth?: string): Promise<ServerResponse<T>> {
        const results = await fetch(endpoint, {
            method: 'POST',
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "Token": auth
            },
            body: JSON.stringify(payload)
        });

        if (results.ok) {
            try {
                const data = await results.json();
                return {
                    status: results.status,
                    data
                };
            } catch (e) {
                return null
            }
        } else {
            console.log(`Request failed ${results.status} -> ${results.statusText}`)
            return {
                status: results.status,
                statusText: results.statusText
            };

        }
    }

    export async function getJson<T>(endpoint: string, auth?: string): Promise<ServerResponse<T>> {
        const results = await fetch(endpoint, {
            method: 'GET',
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "Token": auth
            }
        });
        if (results.ok) {
            const data = await results.json();
            return {
                status: results.status,
                data
            };
        } else {
            console.log(`Request failed ${results.status} -> ${results.statusText}`)
            return {
                status: results.status,
                statusText: results.statusText
            };

        }
    }

}