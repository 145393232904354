import { OpenAI } from "./OpenAI";
import { CompanyOverview, Domain, SearchContext } from "./Workflow";

export interface TargetActivity {
    label: string,
    details: string,
    keywords: string[]
}

export module AI {
    export async function suggestApplicationDomains_local(description: string) {
        let response = await fetch('/resources/steam_domains_old.json');
        console.log(response);
        let data = await response.json();
        return data;
    }
    export async function suggestApplicationDomains(description: string): Promise<Domain[]> {
        const response: any = await OpenAI.autocomplete([{
            role: 'user',
            content: `Voici la description d\'une idée : "${description}"\npropose au moins 5 domaines d'applications spécifiques avec quelques details et des termes de recherche permettant d'identifier les types d'entreprises qui pourraient exploiter cette technologie`
        }],
            {
                "type": "json_schema",
                "json_schema": {
                    "name": "application_domains",
                    "schema": {
                        "type": "object",
                        "properties": {
                            "domains": {
                                "type": "array",
                                "items": {
                                    "type": "object",
                                    "properties": {
                                        "label": { "type": "string" },
                                        "details": { "type": "string" },
                                        "keywords": {
                                            "type": "array",
                                            "items": {
                                                "type": "string",
                                            }
                                        }
                                    },
                                    "required": ["label", "details", "keywords"],
                                    "additionalProperties": false
                                }
                            }
                        },
                        "required": ["domains"],
                        "additionalProperties": false
                    },
                    "strict": true
                }
            }
        );
        let message = response.choices[0].message;
        const suggestedApplications = JSON.parse(message.content);
        const domainsWithId = suggestedApplications.domains.map(domain => {
            return Object.assign(domain, {
                id: domain.label
            });
        })
        return domainsWithId;
    }

    export async function createDomainDetails(techDescription: string, domainName: string): Promise<Domain> {
        const response: any = await OpenAI.autocomplete([
            {
                role: 'user',
                content: `Voici la description d\'une idée : "${techDescription}"\n`
            }, {
                role: 'user',
                content: `Je souhaite identifier des entreprises pour l'exploiter dans le cadre suivant : ${domainName}"\n`
            }, {
                role: 'user',
                content: `indique dans le champ details la pertinence pour ce domaine et ajout ands le champ keyword les termes de recherche permettant d'identifier les types d'entreprises qui pourraient exploiter cette technologie`
            }],
            {
                "type": "json_schema",
                "json_schema": {
                    "name": "target_activities",
                    "schema": {
                        "type": "object",
                        "properties": {
                            "label": { "type": "string" },
                            "details": { "type": "string" },
                            "keywords": {
                                "type": "array",
                                "items": {
                                    "type": "string",
                                }
                            }
                        },
                        "required": ["label", "details", "keywords"],
                        "additionalProperties": false
                    },
                    "strict": true
                }
            }
        );
        let message = response.choices[0].message;
        const suggestedApplications = JSON.parse(message.content);
        return suggestedApplications;
    }

    export async function suggestCompanies(techDescription: string): Promise<TargetActivity[]> {
        const response: any = await OpenAI.autocomplete([
            {
                role: 'user',
                content: `Voici la description d\'une idée : "${techDescription}"\n`
            }, {
                role: 'user',
                content: `Je souhaite identifier des entreprises pour l'exploiter dans leurs produits"\n`
            }, {
                role: 'user',
                content: `Indique moi les catégories entreprises que je devrais cibler avec un bref détail de la raison pour laquelle l'activité est intéressante ainsi que les mots clés précis et spécifiques qui caractérisent ces entreprises`
            }],
            {
                "type": "json_schema",
                "json_schema": {
                    "name": "target_activities",
                    "schema": {
                        "type": "object",
                        "properties": {
                            "activities": {
                                "type": "array",
                                "items": {
                                    "type": "object",
                                    "properties": {
                                        "label": { "type": "string" },
                                        "details": { "type": "string" },
                                        "keywords": {
                                            "type": "array",
                                            "items": {
                                                "type": "string",
                                            }
                                        }
                                    },
                                    "required": ["label", "details", "keywords"],
                                    "additionalProperties": false
                                }
                            }
                        },
                        "required": ["activities"],
                        "additionalProperties": false
                    },
                    "strict": true
                }
            }
        );
        let message = response.choices[0].message;
        const suggestedApplications = JSON.parse(message.content);
        return suggestedApplications.activities;
    }

    export async function suggestKeywords(techDescription:string, searchContext: SearchContext): Promise<string[]> {
        const response: any = await OpenAI.autocomplete([
            {
                role: 'user',
                content: `Voici la description d\'une idée : "${techDescription}"\n`
            },
            {
                role: 'user',
                content: `Je souhaite identifier des entreprises pour l'exploiter dans le cadre suivant : ${searchContext.domain.label} et en particulier dans les activitiés suivantes ${searchContext.domain.label}"\n`
            }, {
                role: 'user',
                content: `Propose des mots clés qui permettraient d'affiner ma recherche en les classant par pertinence`
            }],
            {
                "type": "json_schema",
                "json_schema": {
                    "name": "target_activities",
                    "schema": {
                        "type": "object",
                        "properties": {
                            "keywords": {
                                "type": "array",
                                "items": {
                                    "type": "string",
                                }
                            }
                        },
                        "required": ["keywords"],
                        "additionalProperties": false
                    },
                    "strict": true
                }
            }
        );
        let message = response.choices[0].message;
        const data = JSON.parse(message.content);
        return data.keywords;
    }

    export async function suggestCompaniesKeywords(query: string, companies: CompanyOverview[], maxCount: number): Promise<string[]> {
        const response: any = await OpenAI.autocomplete([
            {
                role: 'user',
                content: `Voici une liste d'entreprises : "${JSON.stringify(companies)}"\n obtenues avec la requete suivante : "${query}"`
            },
            {
                role: 'user',
                content: `Propose des mots clés qui permettraient d'affiner ma recherche en les classant par pertinence en évitant de répeter le contenu de la requete`
            }],
            {
                "type": "json_schema",
                "json_schema": {
                    "name": "target_activities",
                    "schema": {
                        "type": "object",
                        "properties": {
                            "keywords": {
                                "type": "array",
                                "items": {
                                    "type": "string",
                                }
                            }
                        },
                        "required": ["keywords"],
                        "additionalProperties": false
                    },
                    "strict": true
                }
            }
        );
        let message = response.choices[0].message;
        const data = JSON.parse(message.content);
        return data.keywords.slice(0, Math.min(maxCount, data.keywords.length));
    }
}