import * as React from "react";
import { Subject, Subscription } from "rxjs";

type Command = {
    name: string,
    data: any
}

const commandFlow = new Subject<Command>();


export module RxCommand {
    
    export function sendCommand(name: string, data?: any) {
        commandFlow.next({
            name,
            data
        });
    }

    export function listenCommand(name: string, callback: (data: any) => void): Subscription {
        return commandFlow.subscribe((command) => {
            if (command.name === name) {
                callback(command.data);
            }
        });
    }

    export function useCommandHandler(name: string, callback: (data: any) => void, dependencies?: any[]) {
        React.useEffect(() => {
            const subscription = listenCommand(name, (data) => {
                callback(data);
            });
            return () => {
                subscription.unsubscribe();
            }
        }, dependencies ?? []);
    }

}