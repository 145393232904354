import * as React from 'react'

import { Domain, SearchContext, Workflow } from '../../core/Workflow';
import { DomainSearch } from './DomainSearch';
import { DomainCompanySearch } from './DomainCompanySearch';

import { PageSwitcher, PageSwitcherItem } from '@sleekly-ui/layout/PageSwitcher';
import { ChevronRightIcon } from '@sleekly-ui/icons/ChevronRightIcon';
import { ScreenTitle } from '@sleekly-ui/components/ScreenTitle';

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const Exploration: React.FC<Props> = ({ }: Props) => {

    const pages: PageSwitcherItem[] = [
        {
            id: 'domain-search',
            render: (props: any) => {
                const { openSearchContext } = props;
                return <DomainSearch openSearchContext={openSearchContext}></DomainSearch>
            }
        },
        {
            id: 'company-search',
            render: (props: any) => {
                const { openSearchContext, searchContext } = props;
                return <DomainCompanySearch openSearchContext={openSearchContext} searchContext={searchContext}></DomainCompanySearch>
            }
        }
    ]

    const [searchContext, setSearchContext] = React.useState<SearchContext>(null);

    const [currentPage, setCurrentPage] = React.useState('domain-search');

    const openSearchContext = (domain: Domain, keyword: string) => {
        const context: SearchContext = {
            domain,
            keyword,
            additionnalKeywords: []
        };
        Workflow.addExploredDomain(domain, keyword);
        Workflow.addDomain(domain);
        setSearchContext(context);
        setCurrentPage('company-search');
    }

    const removeContextKeyword = () => {
        const context: SearchContext = {
            domain: searchContext.domain,
            keyword: null,
            additionnalKeywords: []
        };
        setSearchContext(context);
    }

    const closeSearchContext = () => {
        setSearchContext(null);
    }


    const domainTitleStyle = searchContext?.keyword ? '' : 'text-primary';

    const domainSearchTitleStyle = currentPage === 'domain-search' ? 'text-primary' : '';

    const openDomainSearchPage = () => {
        closeSearchContext();
        closeSearchContext();
        setCurrentPage('domain-search');
    }

    return <div className='flex-1 flex flex-col overflow-hidden'>

        <ScreenTitle>
            <span onClick={openDomainSearchPage} className='cursor-pointer hover:text-primary'>Recherche de domaines</span>
            {
                searchContext && < >
                    <div>
                        <ChevronRightIcon></ChevronRightIcon>
                    </div>
                    <span className={`text-sm cursor-pointer hover:text-primary ${domainTitleStyle}`} onClick={removeContextKeyword}>{searchContext.domain.label}</span>
                    {
                        searchContext.keyword && <>
                            <ChevronRightIcon></ChevronRightIcon>
                            <span className='text-sm text-primary pointer-events-none'>{searchContext.keyword}</span>
                        </>
                    }
                </>
            }
        </ScreenTitle>
        <div className='flex-1 flex overflow-hidden'>
            <PageSwitcher activePageId={currentPage}
                pageProps={{ searchContext, openSearchContext }}
                pages={pages}></PageSwitcher>
        </div>
    </div >
}