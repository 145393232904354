import * as React from 'react'

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const ArrowDropRightIcon: React.FC<Props> = ({ }: Props) => {

    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
        className='h-4 w-4'>
        <path d="M16 12L10 18V6L16 12Z"></path>
    </svg>
}