import * as React from 'react'

import { CenterColumn, ColumnPageLayout, SectionLabel, SideColumn } from '../../ext/Layout'

import { CompanyOverview, Domain, DomainSummary, SearchContext, Workflow } from '../../core/Workflow'
import { SelectedCompaniesWidget } from '../../components/SelectedCompaniesWidget'
import { CompanySearch } from './CompanySearch'
import { Keyword, KeywordsList } from '../../components/KeywordsList'
import { SearchFiltersWidget } from '../../components/SearchFiltersWidget'
import { CompanyDetailsWidget } from '../../components/company-info/CompanyDetailsWidget'
import { ScreenTitle } from '@sleekly-ui/components/ScreenTitle'

type Props = {
    searchContext: SearchContext,
    openSearchContext: (domain: Domain, keyword: string) => void
} & React.HTMLAttributes<HTMLDivElement>

export const DomainCompanySearch: React.FC<Props> = ({ searchContext, openSearchContext }: Props) => {

    const domainSummaries = Workflow.useDomainSumaries();

    const [selectedCompany, setSelectedCompany] = React.useState<CompanyOverview>(null);

    const formatKeywords = (domain: Domain, domainSummaries: DomainSummary[], searchContext: SearchContext): Keyword[] => {
        if (domain) {
            const domainSummary = domainSummaries.find(d => d.id === searchContext.domain.id);

            return domain.keywords.map(k => {
                return {
                    text: k,
                    explored: domainSummary?.exploredKeywords.includes(k),
                    selected: k === searchContext.keyword
                }
            });
        } else {
            return [];
        }
    }

    const keywords = formatKeywords(searchContext?.domain, domainSummaries, searchContext);

    const companyFocusModeColumnStyle = selectedCompany ? 'hidden' : '';
    const companyFocusModeDetailsStyle = selectedCompany ? '' : 'hidden';
    const companyFocusModeListStyle = selectedCompany ? 'max-w-md' : '';

    return <ColumnPageLayout>
        <SideColumn className={companyFocusModeColumnStyle}>
            <>
                <div className='flex-1 flex flex-col'>
                    <ScreenTitle level='secondary'>Activités et Mots clés</ScreenTitle>
                    <div className='overflow-auto flex-1 border border-neutral-700 rounded-lg p-4'>
                        <KeywordsList keywords={keywords}
                            onSelectKeyword={(keyword) => openSearchContext(searchContext.domain, keyword)}
                            onAddKeyword={(keyword) => {
                                const updatedDomain = Workflow.addKeywordToDomain(searchContext.domain, keyword);
                                openSearchContext(updatedDomain, keyword)
                            }}
                        ></KeywordsList>
                    </div>
                </div>
                <div className='h-4'></div>
                <div className='flex-1 flex flex-col overflow-hidden'>
                    <ScreenTitle level='secondary'>Filtres sur les résultats de recherche</ScreenTitle>
                    <div className='flex-1 flex flex-col overflow-auto'>
                        <SearchFiltersWidget></SearchFiltersWidget>
                    </div>
                </div>
            </>
        </SideColumn>
        <CenterColumn className={`${companyFocusModeListStyle}`}>
            <CompanySearch
                searchContext={searchContext}
                onUpdateSearchContext={() => openSearchContext}
                onSelectedCompanyChanged={setSelectedCompany}></CompanySearch>
        </CenterColumn>
        <CenterColumn className={`${companyFocusModeDetailsStyle}`}>
            {
                selectedCompany && <CompanyDetailsWidget company={selectedCompany}
                    onCloseFocus={() => setSelectedCompany(null)}></CompanyDetailsWidget>
            }
        </CenterColumn>
        {/* <SideColumn>
            <SelectedCompaniesWidget compact={true}></SelectedCompaniesWidget>
        </SideColumn> */}
    </ColumnPageLayout>
}