import * as React from 'react'
import { Exploration } from './exploration/Exploration';
import { CompanySearchPage } from './company-search/CompanySearchPage';

import { ProspectionPage } from './prospection/ProspectionPage';
import { ProjectSummary } from './project-summary/ProjectSummary';
import { Technology } from './technology/Technology';

import { Workflow } from '../core/Workflow';

import { AppState } from '@sleekly-ui/AppState';
import { PageSwitcher, PageSwitcherItem } from '@sleekly-ui/layout/PageSwitcher';

export const AppActivities: PageSwitcherItem[] = [
    {
        id: 'project-summary',
        title: 'Résumé Project',
        render: () => {
            return <ProjectSummary ></ProjectSummary>
        }
    },

    {
        id: 'technology',
        title: 'Contexte Technologie',
        render: () => {
            return <Technology></Technology>
        }
    },
    {
        id: 'domains',
        title: 'Domaines d\'application',
        render: () => {
            return <Exploration></Exploration>
        }
    },
    {
        id: 'search-companies',
        title: 'Recherche Entreprises',
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"></path>
        </svg>,
        render: () => {
            return <CompanySearchPage></CompanySearchPage>
        }
    },
    {
        id: 'prospect',
        title: 'Entreprises sélectionnées',
        render: () => {
            return <ProspectionPage></ProspectionPage>
        }
    }
]

const SecondaryActivities = [


]


type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const ProjectHome: React.FC<Props> = ({ }: Props) => {

    const [currentActivity, setCurrentAcitivy] = AppState.useState<string>('app-activity', AppActivities[0].id);

    React.useEffect(() => {
        AppState.pushState(Workflow.PINNED_COMPANIES, [])
    }, []);

    return <div className='flex-1 flex flex-col overflow-hidden'>
        <div className='bg-base-200 flex mx-1'>
            <div role="tablist" className="tabs tabs-boxed">
                {
                    AppActivities.map(activity => {
                        const activeStyle = currentActivity == activity.id ? 'tab-active' : '';
                        return <a key={activity.title}
                            role="tab"
                            className={`tab ${activeStyle}`}
                            onClick={() => setCurrentAcitivy(activity.id)}>{activity.title}</a>
                    })
                }
            </div>
        </div>
        <div className='flex-1 flex flex-col p-4 overflow-hidden'>
            <PageSwitcher activePageId={currentActivity} pages={AppActivities} ></PageSwitcher>
        </div>
    </div>
}