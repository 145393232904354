import * as React from 'react'

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const ArrowDropDownIcon: React.FC<Props> = ({ }: Props) => {

    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
        className='h-4 w-4'>
        <path d="M12 16L6 10H18L12 16Z"></path>
    </svg>
}