import * as React from 'react'
import { DomainSummary } from '../core/Workflow';

import { Card } from '@sleekly-ui/components/Card';
import { DataBadge } from '@sleekly-ui/components/DataBadge';
import { CompanyIcon } from '@sleekly-ui/icons/CompanyIcon';
import { Tooltip } from '@sleekly-ui/components/Tooltip';

type Props = {
    domain: DomainSummary
} & React.HTMLAttributes<HTMLDivElement>

export const DomainSummaryCard:React.FC<Props> = ({domain}: Props) => {

    const exploredKeywords = domain.keywords.filter(keyword => {
        const explored = domain.exploredKeywords.includes(keyword)
        return explored;
    });

    return <Card key={domain.label}>

        <div className='flex'>
            <Tooltip text="Execute une recherche d'entreprises" tooltipOrientation='right'>
                <div className='text-left text-sm font-bold cursor-pointer'>{domain.label}</div>
            </Tooltip>
            <div className='flex-1'></div>
            <DataBadge entries={[
                {
                    icon: <CompanyIcon></CompanyIcon>,
                    value: '26 / 3600',
                    tooltip: 'Entreprises candidates / disponibles'
                }
            ]}
                tooltipOrientation='left'></DataBadge>
        </div>


        {
            exploredKeywords.map(keyword => {
                return <div key={keyword} className='flex ml-4 my-1'>
                    <span className='text-sm'>{keyword}</span>
                    <div className='flex-1'></div>
                    <DataBadge entries={[
                        {
                            icon: <CompanyIcon></CompanyIcon>,
                            value: '6 / 200',
                            tooltip: 'Entreprises candidates / disponibles'
                        }
                    ]}
                        tooltipOrientation='left'></DataBadge>
                </div>
            })
        }
    </Card>
}