import { AppState } from "@sleekly-ui/AppState"
import * as moment from "moment"

export type Project = {
    title: string,
    description: string,
    alt_descriptions: string[]
}

export interface Domain {
    id: string,
    label: string,
    details: string,
    keywords: string[]
}

export interface DomainSummary extends Domain {
    explored: boolean,
    exploredKeywords: string[]
}

export type SearchContext = {
    domain: Domain,
    keyword: string,
    additionnalKeywords: string[],
}

export type CompanyOverview = {
    id: string
    name: string,
    description: string,
    url: string
}

export type PinnedCompany = {
    id: string
    name: string,
    description: string,
    url: string,
    date: string,
    interest: string,

    domainId?: string,
    keywords?: string[]
    query?: string
}

export type SearchHistory = {
    id: string,
    query: string,
    date: string,
    count: number
}

export type DomainHistory = {
    id:string,
    domain: Domain,
    keywords: string[],
    date: string
}

export namespace Workflow {

    export const SUGGESTED_DOMAINS = 'last-suggested-domains';

    export const CANDIDATE_DOMAINS = 'candidate-domains';

    export const EXPLORED_DOMAINS = 'explored-domains';

    export const PINNED_COMPANIES = 'pinned-companies';

    export const DISCARDED_COMPANIES = 'discarded-companies';

    export const SEARCH_HISTORY = 'search-history';

    export const DOMAIN_HISTORY = 'domain-history';

    export function initialize() {
        AppState.pushState(Workflow.SUGGESTED_DOMAINS, []);
        AppState.pushState(Workflow.CANDIDATE_DOMAINS, []);
        AppState.pushState(Workflow.EXPLORED_DOMAINS, []);
        AppState.pushState(Workflow.DOMAIN_HISTORY, []);
    }


    export function addDomain(domain: Domain) {
        const existing: Domain[] = AppState.getState(Workflow.CANDIDATE_DOMAINS) ?? [];
        // TODO check if a domain exists before adding
        const exists = existing.find(d => {
            return d.id === domain.id
        });
        if (!exists) {
            const update = [].concat(existing, domain);
            AppState.pushState(Workflow.CANDIDATE_DOMAINS, update);
        } else {
            const update = [].concat(existing);
            AppState.pushState(Workflow.CANDIDATE_DOMAINS, update);
        }

    }

    export function removeDomain(domain: Domain) {
        const existing: Domain[] = AppState.getState(Workflow.CANDIDATE_DOMAINS) ?? [];
        const update = existing.filter(d => {
            return d.id !== domain.id;
        });
        AppState.pushState(Workflow.CANDIDATE_DOMAINS, update);
    }

    export function addKeywordToDomain(domain: Domain, keyword: string): Domain {
        const existing: Domain[] = AppState.getState(Workflow.CANDIDATE_DOMAINS) ?? [];
        const update = existing.map(d => {
            if (d.id === domain.id) {
                domain.keywords.push(keyword);
            }
            return d
        });
        AppState.pushState(Workflow.CANDIDATE_DOMAINS, update);
        return update.find(d => d.id === domain.id)
    }

    export function useDomainSumaries() {
        const [allDomains, setAllDomains] = AppState.useState<Domain[]>(Workflow.CANDIDATE_DOMAINS, []);

        const [exploredDomains, setExploredDomains] = AppState.useState<Domain[]>(Workflow.EXPLORED_DOMAINS, []);

        const domainSummaries: DomainSummary[] = allDomains.map(domain => {
            const explored = exploredDomains.find(d => d.id === domain.id);
            const summary: DomainSummary = Object.assign({}, domain, {
                explored: !!explored,
                exploredKeywords: explored?.keywords ?? []
            })
            return summary;
        })

        domainSummaries.sort((a, b) => a.label.localeCompare(b.label));
        return domainSummaries;
    }

    export function filterDiscardedCompanies(companies: CompanyOverview[]) {
        const discardedCompanies: any[] = AppState.getState(DISCARDED_COMPANIES) ?? [];
        const visibleCompanies = companies
            .filter(company => {
                const isDiscarded = discardedCompanies.find(d => d.name === company.name);
                return !isDiscarded;
            });
        return visibleCompanies;
    }

    export function addDomainHistory(domainHistory: DomainHistory) {
        const history = AppState.getState(DOMAIN_HISTORY) ?? [];
        const update = [].concat(history, domainHistory);
        AppState.pushState(DOMAIN_HISTORY, update);
    }

    export function useDomainHistory(): DomainHistory[] {
        const [history] = AppState.useState<DomainHistory[]>(Workflow.DOMAIN_HISTORY, []);
        return history
    }

    export function addSearchHistory(historyEntry: SearchHistory) {
        const history: any[] = AppState.getState(SEARCH_HISTORY) ?? [];
        const exists = history.find(e => e.query === historyEntry.query);
        if (exists) {
            Object.assign(exists, historyEntry, {
                count: exists.count + 1
            })
        } else {
            const update = [].concat(history, historyEntry);
            AppState.pushState(SEARCH_HISTORY, update);
        }
    }

    export function addPinnedCompanies(company: CompanyOverview, searchContext?: SearchContext,
        query?: string,) {
        const companies: PinnedCompany[] = AppState.getState(PINNED_COMPANIES) ?? [];
        const pinnedCompany: PinnedCompany = Object.assign({
            id: company.name,
            date: moment().toISOString(),
            domainId: searchContext?.domain?.id,
            keywords: searchContext?.domain?.keywords,
            interest: '',
            query
        }, company);
        const update = [].concat(companies, pinnedCompany);
        AppState.pushState(PINNED_COMPANIES, update);
    }

    export function removePinnedCompany(company: PinnedCompany) {
        const companies: PinnedCompany[] = AppState.getState(PINNED_COMPANIES) ?? [];
        const update = companies.filter(c => c.name !== company.name);
        AppState.pushState(PINNED_COMPANIES, update);
    }

    export function addExploredDomain(domain: Domain, keyword?: string) {

        // const history: DomainHistory = {
        //     domain,
        //     keywords: [keyword],
        //     date: moment().toISOString()
        // };
        // Workflow.addDomainHistory(history);

        const explored: Domain[] = AppState.getState(Workflow.EXPLORED_DOMAINS) ?? [];

        const existing = explored.find(d => d.id === domain.id);

        const domainUpdate: Domain = existing ?? {
            id: domain.id,
            label: domain.label,
            details: domain.details,
            keywords: []
        };

        if (keyword) {
            const exists = domainUpdate.keywords.find(k => k === keyword);
            if (!exists) {
                domainUpdate.keywords.push(keyword);
            }
        }

        const exploredUpdate = explored.filter(d => d.id !== domain.id);

        const update = [].concat(exploredUpdate, domainUpdate);
        AppState.pushState(Workflow.EXPLORED_DOMAINS, update);
    }
}