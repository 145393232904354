import * as React from 'react'

type ColumnPageLayoutProps = {

} & React.HTMLAttributes<HTMLDivElement>

export const ColumnPageLayout: React.FC<ColumnPageLayoutProps> = ({ children }: ColumnPageLayoutProps) => {

    return <div className='flex-1 flex p-4 overflow-hidden'>
        {children}
    </div>
}


type SideColumnProps = {

} & React.HTMLAttributes<HTMLDivElement>

export const SideColumn: React.FC<SideColumnProps> = ({ children, className}: SideColumnProps) => {

    return <div className={`max-w-xs w-full flex flex-col overflow-hidden ${className}`}>
        {children}
    </div>
}

type CenterColumnProps = {
    
} & React.HTMLAttributes<HTMLDivElement>

export const CenterColumn: React.FC<CenterColumnProps> = ({ children, className }: CenterColumnProps) => {

    return <div className={`px-4 flex-1 flex flex-col items-center overflow-hidden ${className}`}>
        <div className='flex-1 flex flex-col w-full max-w-screen-md overflow-hidden'>
            {children}
        </div>
    </div>
}

type SectionLabelProps = {

} & React.HTMLAttributes<HTMLDivElement>

export const SectionLabel: React.FC<SectionLabelProps> = ({ children, className}: SectionLabelProps) => {

    return <div className={`text-xs opacity-50 text-primary ${className}`}>
        {children}
    </div>
}
