import * as React from 'react'

type Props = {
    level?: 'primary' | 'secondary' | 'content'
} & React.HTMLAttributes<HTMLDivElement>

export const ScreenTitle: React.FC<Props> = ({ children, className, level}: Props) => {
    const styles = {
        'primary' : 'text-xl',
        'secondary' : 'text-md',
        'content' : 'text-sm',
    }

    const currentStyle = level ? styles[level] : styles['primary'];
    return <div className={`mb-2 flex items-center font-thin space-x-2 ${currentStyle} ${className}`}>
        {children}
    </div>
}