import * as React from 'react'

export type PageSwitcherItem = {
    id: string,
    title?: string,
    icon?: React.ReactElement,
    render: (props?: any) => React.ReactElement
}

type Props = {
    activePageId: string,
    pages: PageSwitcherItem[],
    pageProps?: any
} & React.HTMLAttributes<HTMLDivElement>

export const PageSwitcher:React.FC<Props> = ({activePageId, pages, pageProps}: Props) => {
    const activePage = pages.find(p => p.id === activePageId);
    return <>
        {
            activePage && activePage.render(pageProps)
        }
    </>
}