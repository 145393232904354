import * as React from 'react'
import * as moment from 'moment'

import { DomainHistory } from '../../core/Workflow'

import { Card } from '@sleekly-ui/components/Card';

import { Tooltip } from '@sleekly-ui/components/Tooltip'
import { DataBadge } from '@sleekly-ui/components/DataBadge'
import { UserAvatar } from '@sleekly-ui/components/UserAvatar'
import { ChevronRightIcon } from '@sleekly-ui/icons/ChevronRightIcon'
import { CompanyIcon } from '@sleekly-ui/icons/CompanyIcon'

type Props = {
    item: DomainHistory
} & React.HTMLAttributes<HTMLDivElement>

export const DomainHistoryCard: React.FC<Props> = ({ item }: Props) => {

    return <div className='flex' key={item.date}>
        <div className='p-2'>
            <Tooltip text='User' tooltipOrientation='right'>
                <UserAvatar userName='U'></UserAvatar>
            </Tooltip>
        </div>
        <Card
            className='flex-1 cursor-pointer'>
            <div className='flex'>
                <div className='flex-1 flex flex-wrap items-center space-x-1'>
                    <Tooltip text="Ouvre la page d'exploration domaine" tooltipOrientation='right'>
                        <span className='font-bold'>{item.domain.label}</span>
                    </Tooltip>
                    {
                        item.keywords.map((k) => {
                            return <div key={k}
                                className='flex-1 flex flex-wrap items-center space-x-1'>
                                <span>
                                    <ChevronRightIcon></ChevronRightIcon>
                                </span>
                                <span>{k}</span>
                            </div>
                        })
                    }
                </div>
                <DataBadge entries={[
                    {
                        icon: <CompanyIcon></CompanyIcon>,
                        value: '6 / 200',
                        tooltip: 'Entreprises candidates / disponibles'
                    }
                ]}
                    tooltipOrientation='left'></DataBadge>
            </div>

            <div className='flex mt-1'>
                <div className='flex-1'></div>
                <Tooltip text='Dernière ouverture' tooltipOrientation='left'>
                    <div className='text-xs opacity-50'>{moment(item.date).format("DD/MM/YYYY - HH:mm")}</div>
                </Tooltip>
            </div>
        </Card>
    </div>
}