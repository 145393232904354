import * as React from 'react'

type Props = {
    text: string,
    tooltipOrientation?: 'left' | 'right' | 'top' | 'bottom'
} & React.HTMLAttributes<HTMLDivElement>

export const Tooltip: React.FC<Props> = ({ text, children, tooltipOrientation }: Props) => {
    const or = tooltipOrientation ? tooltipOrientation :  'top';
    const orientationStyle = `tooltip-${or}`;

    // use tippy ?? https://atomiks.github.io/tippyjs/v6/getting-started/
    // or floating ui https://floating-ui.com/
    return <div className={`tooltip ${orientationStyle}`} data-tip={text}>
        {children}
    </div>
}