import * as React from 'react'
import { Project } from '../core/Workflow';

type Props = {
    project: Project,
    onEdit?(project: Project)
} & React.HTMLAttributes<HTMLDivElement>

export const ProjectSettings: React.FC<Props> = ({ project, onEdit}: Props) => {

    const update = (prop: string, value: string) => {
        const copy = Object.assign({}, project);
        copy[prop] = value;
        onEdit && onEdit(copy);
    }

    return <div className='flex-1 flex flex-col items-center p-4 overflow-hidden'>
        <div className='max-w-2xl flex-1 w-full overflow-auto p-2'>
            <div className='text-xs pb-1 opacity-50'>Nom du projet *</div>
            <input className='input input-bordered w-full'
                value={project?.title}
                placeholder='Nom de la technologie'
                onChange={(e) => update('title', e.target.value)}></input>

            <div className='text-xs pb-1 opacity-50 my-2 mt-4'>Description de la technologie du projet</div>
            <textarea className="textarea textarea-bordered w-full"
                placeholder="Description de la technologie"
                rows={7}
                value={project?.description}
                onChange={(e) => update('description', e.target.value)}></textarea>
        </div>
    </div>
}