import * as React from "react";
import { RxCommand } from "./Command";


const appState = {}

export namespace AppState {

    export function pushState<T>(key: string, value: T) {
        appState[key] = value;
        RxCommand.sendCommand('app-state-update', {
            key,
            value
        })
    }

    export function getState<T>(name: string): T {
        return appState[name]
    }

    export function useState<T>(name: string, initialValue?: T): [T, (v: T) => void] {

        const initializing = initialValue && !appState[name];
        if (initializing) {
            appState[name] = initialValue;
        }
        const [value, setValue] = React.useState<T>(appState[name]);
        React.useEffect(() => {
            const subscription = RxCommand.listenCommand('app-state-update', (data) => {
                if (data.key === name) {
                    setValue(data.value);
                }
            });
            return () => {
                subscription.unsubscribe();
            }
        }, []);

        if (initializing) {
            setTimeout(() => {
                AppState.pushState(name, initialValue);
            }, 50);
        }
        
        return [value, (newValue: T) => {
            pushState(name, newValue);
        }];
    }

}