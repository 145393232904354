import * as React from 'react'
import { AppState } from '../AppState';

type Props = {
} & React.HTMLAttributes<HTMLDivElement>

const DEFAULT_THEME = 'dark';

export const AppPage: React.FC<Props> = ({ children }: Props) => {

    const [theme, setTheme] = AppState.useState<string>('app-theme', DEFAULT_THEME);
    
    return <div data-theme={theme} className='h-full w-full overflow-hidden flex flex-col'>
        {children}
    </div>
}