import * as React from 'react'
import { useLoggedInUser } from '../login/Login'
import { MainPage } from '@sleekly-ui/layout/MainPage';
import { UserEnterEvent } from "@kinai-medical/UserEnterEvent";
import { FlowaveClient } from '@sleekly-ui/flowave/FlowaveClient';
import { useNavigate } from 'react-router-dom';
import { List } from '@sleekly-ui/components/List';
import * as moment from 'moment';
type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const AnalyticsPage: React.FC<Props> = ({ }: Props) => {

    const navigate = useNavigate();
    let user = useLoggedInUser();

    let [userEnterEvents, setUserEnterEvents] = React.useState<UserEnterEvent[]>([]);

    React.useEffect(() => {
        if(user) {
            loadEvents(user)
        }
    }, [user]);


    const loadEvents = async (user) => {
        const flowave = new FlowaveClient(`/api/analytics`, user.token);
        try {
            const events: UserEnterEvent[] = await flowave.getEntities('UserEnterEvent');
            events.sort((a, b) => b.date.localeCompare(a.date));
            setUserEnterEvents(events);
            // console.log(events);
            
        } catch (e) {
            navigate("/login");
            // console.log(e);
        }
    }


    return <MainPage className='p-4'>
        <List
            items={userEnterEvents}
            renderItem={(item: UserEnterEvent) => {
                return <div className='my-1 space-x-2'>
                    <span>{moment(item.date).format('DD/MM/YYYY - HH:mm')}</span>
                    <span>{item.user_id}</span>
                </div>
            }}>
        </List>
    </MainPage>
}