import * as React from 'react'

import { WebSearch } from '../../core/WebSearch';
import { CandidateContactCard } from '../../components/CandidateContactCard';
import { PinnedCompany } from '../../core/Workflow';
import { SelectedCompaniesWidget } from '../../components/SelectedCompaniesWidget';
import { CompanyInfoDialog } from '../../components/company-info/CompanyInfoDialog';

import { CenterColumn, ColumnPageLayout, SectionLabel, SideColumn } from '../../ext/Layout'

import { openDialog } from '../../ext/Dialog';

import { Tooltip } from '@sleekly-ui/components/Tooltip';
import { ProgressIndicator } from '@sleekly-ui/components/ProgressIndicator';
import { ShareIcon } from '@sleekly-ui/icons/ShareIcon';
import { CloseIcon } from '@sleekly-ui/icons/CloseIcon';
import { EyeFillIcon } from '@sleekly-ui/icons/EyeFillIcon';
import { ScreenTitle } from '@sleekly-ui/components/ScreenTitle';

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const ProspectionPage: React.FC<Props> = ({ }: Props) => {

    const [selectedCompany, setSelectedCompany] = React.useState<PinnedCompany>(null);

    const [candidateContacts, setCandidateContacts] = React.useState([]);

    const [progessIndicatorVisible, setProgessIndicatorVisible] = React.useState(false);

    const [companyContacts, setCompanyContacts] = React.useState([]);

    const searchContacts = (company: PinnedCompany) => async () => {
        setCandidateContacts([]);
        setProgessIndicatorVisible(true);
        setSelectedCompany(company);

        const searchResults = await WebSearch.webSearch(company.name, "linkedin.com/in");
        console.log(searchResults);
        const contacts = searchResults.results.map(res => {
            const name = res.title.replace(' | LinkedIn', '')
                .replace(' - LinkedIn', '');
            return {
                name,
                description: res.content,
                url: res.url
            }
        });
        setProgessIndicatorVisible(false);
        setCandidateContacts(contacts);
    }

    const pinContact = (contact) => () => {
        const update = [].concat(contact, companyContacts);
        setCompanyContacts(update);
        deleteCandidateContact(contact)();
    }

    const deleteContact = (contact) => () => {
        const update = companyContacts.filter(c => c.name !== contact.name);
        setCompanyContacts(update);
    }

    const deleteCandidateContact = (contact) => () => {
        const update = candidateContacts.filter(c => c.name !== contact.name);
        setCandidateContacts(update);
    }

    const toogleCompanySelection = (company: PinnedCompany) => {
        if (selectedCompany?.name === company.name) {
            setSelectedCompany(null);
        } else {
            setSelectedCompany(company);
        }
    }

    const closeSearchContacts = () => {
        setCandidateContacts([])
    }

    const openCompanyDetails = () => {
        openDialog('company-info');
    }

    const closeCompanyFocus = () => {
        setSelectedCompany(null)
        setCandidateContacts([])
    }

    return <>
        <ScreenTitle>
            <span>Entreprises Sélectionnées</span>
            <div className='flex-1'></div>
            <button className='btn btn-sm btn-accent'>
                <ShareIcon></ShareIcon>
                <span>Exporter au format XLS</span>
            </button>
        </ScreenTitle>

        <ColumnPageLayout>
            {
                (selectedCompany && !(progessIndicatorVisible || candidateContacts.length > 0)) && <SideColumn className='max-w-xl'>
                    <SelectedCompaniesWidget selectedCompany={selectedCompany}
                        onSelectCompany={toogleCompanySelection}
                        hideShortcut={true}></SelectedCompaniesWidget>
                </SideColumn>
            }

            {
                !selectedCompany && <CenterColumn>
                    <SelectedCompaniesWidget selectedCompany={selectedCompany}
                        onSelectCompany={toogleCompanySelection}
                        hideShortcut={true}></SelectedCompaniesWidget>
                </CenterColumn>
            }

            {
                selectedCompany && <CenterColumn>
                    <div className='flex items-center space-x-2'>
                        <div className='text-xl font-bold'>{selectedCompany.name}</div>

                        <Tooltip text="Affiche la fiche d'entreprise" tooltipOrientation='bottom'>
                            <button className='btn btn-circle btn-xs btn-ghost'
                                onClick={openCompanyDetails}>
                                <EyeFillIcon></EyeFillIcon>
                            </button>
                        </Tooltip>
                        <div className='flex-1'></div>
                        <button className='btn btn-xs btn-ghost btn-circle'
                            onClick={() => closeCompanyFocus()}>
                            <CloseIcon></CloseIcon>
                        </button>
                    </div>
                    <div className='py-2'>
                        <SectionLabel className='mb-1'>Intérêt</SectionLabel>
                        <textarea className='textarea textarea-bordered w-full' placeholder='Pourquoi cette société est intéressante'></textarea>
                    </div>
                    <div className='flex items-end mb-2'>
                        <SectionLabel className='flex-1'>Contacts</SectionLabel>
                        <button className='btn btn-xs btn-primary'
                            onClick={searchContacts(selectedCompany)}>Rechercher des contacts</button>
                    </div>

                    <div className='flex-1 overflow-auto space-y-2'>
                        {
                            companyContacts.map(contact => {
                                return <CandidateContactCard contact={contact}
                                    onDelete={deleteContact(contact)}></CandidateContactCard>
                            })
                        }

                    </div>
                </CenterColumn>
            }

            {
                (progessIndicatorVisible || candidateContacts.length > 0) && <SideColumn className='max-w-xl'>
                    <ProgressIndicator visible={progessIndicatorVisible}></ProgressIndicator>
                    {
                        candidateContacts.length > 0 && <div className='flex-1 flex flex-col overflow-hidden'>
                            <div className='flex items-center mb-2'>
                                <SectionLabel>Contacts trouvés ({candidateContacts.length})</SectionLabel>
                                <div className='flex-1'></div>
                                <button className='btn btn-xs btn-ghost btn-circle'
                                    onClick={closeSearchContacts}>
                                    <CloseIcon></CloseIcon>
                                </button>
                            </div>
                            <div className='space-y-2 overflow-auto'>
                                {
                                    candidateContacts.map(contact => {
                                        return <div key={contact.name}>
                                            <CandidateContactCard contact={contact}
                                                isPinned={false}
                                                onPin={pinContact(contact)}
                                                onDelete={deleteCandidateContact(contact)}></CandidateContactCard>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    }
                </SideColumn>
            }
        </ColumnPageLayout>

        <CompanyInfoDialog company={selectedCompany}></CompanyInfoDialog>
    </>
}