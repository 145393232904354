import { ServerApi, ServerResponse } from "@sleekly-ui/ServerApi";
import { getCurrentUser, logOut } from "../login/Login";

import { MarketReport } from '@xplorer/MarketReport'
import { SearchMarketReports } from '@xplorer/SearchMarketReports'
import * as moment from "moment";

export type WebSearchResults = {
    results: WebSearchResultEntry[]
}


export type WebSearchResultEntry = {
    title: string,
    content: string,
    url: string,
    engine: string,
    publishedDate?: string
}


export namespace WebSearch {

    export async function webSearch(query: string, scopeUrl: string, engines?: string[]): Promise<WebSearchResults> {
        if (query.length > 0) {

            const user = getCurrentUser();
            const response: ServerResponse<WebSearchResults> = await ServerApi.postJson("/api/search", {
                query,
                site: scopeUrl,
                engines: engines ?? []
            }, user?.token);

            if (response.status === 200) {
                return response.data;

            } else if (response.status === 403) {
                logOut();
            } else {
                console.log(response.status, response.statusText);
            }
        }
    };

    export async function searchMarketReports(keywords: string): Promise<MarketReport[]> {
        if (keywords.length > 0) {

            const user = getCurrentUser();

            const query: SearchMarketReports = {
                user: user?.id,
                keywords: keywords,
                id: moment().toISOString(),
                kind: "SearchMarketReports"
            };
            const response: ServerResponse<MarketReport[]> = await ServerApi.postJson("/api/search_market_reports",
                query
                , user?.token);

            if (response.status === 200) {
                return response.data;

            } else if (response.status === 403) {
                logOut();
            } else {
                console.log(response.status, response.statusText);
            }
        }
    };

    export async function analyzeMarketReport(report: MarketReport): Promise<MarketReport[]> {
        const user = getCurrentUser();

        const response: ServerResponse<MarketReport[]> = await ServerApi.postJson("/api/analyze_market_report",
            report
            , user?.token);

        if (response.status === 200) {
            return response.data;

        } else if (response.status === 403) {
            logOut();
        } else {
            console.log(response.status, response.statusText);
        }
    };

}