import * as React from 'react'
import { closeDialog, Dialog } from '../../ext/Dialog'
import { CompanyDetailsWidget } from './CompanyDetailsWidget'
import { CompanyOverview } from '../../core/Workflow'

type Props = {
    company: CompanyOverview
} & React.HTMLAttributes<HTMLDivElement>

export const CompanyInfoDialog: React.FC<Props> = ({ company }: Props) => {

    return <Dialog dialogId='company-info' className='h-4/5' >
        <div className='flex-1 overflow-hidden flex flex-col '>
            {
                company && <CompanyDetailsWidget company={company} onCloseFocus={() => {
                    closeDialog('company-info')
                }}></CompanyDetailsWidget>
            }
            
        </div>
    </Dialog>
}