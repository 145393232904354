import * as React from 'react'

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const CommentIcon: React.FC<Props> = ({ }: Props) => {

    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
        className='h-4 w-4'>
        <path d="M6.45455 19L2 22.5V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V18C22 18.5523 21.5523 19 21 19H6.45455Z"></path>
    </svg>
}