import * as React from 'react'
import { CompanyOverview } from '../../core/Workflow'

type Props = {
    company: CompanyOverview
} & React.HTMLAttributes<HTMLDivElement>

export const CompanyPatents: React.FC<Props> = ({ company }: Props) => {

    // https://register.epo.org/rssSearch?query=nm+%3D+Siemens&lng=fr
    // https://patents.google.com/?q=(siemens)
    const patents = [
        {
            patentNumber: "WO9113031",
            title: "METHOD FOR TESTING A NUCLEAR FUEL ROD",
            summary: "L'invention concerne un dispositif pour le traitement de l'eau, tel qu'un adoucisseur d'eau, comportant deux composants pour le traitement de l'eau (1, 2) et des moyens de soupape pour que les deux composants soient rendus inopérants pour traiter l'eau à différents intervalles respectifs de sorte qu'au moins un composant (1, 2) soit fonctionnel tout le temps, caractérisé en ce que le moyen de soupape comporte des moyens de soupape de contrôle du débit d'eau et en ce que le dispositif comprend un débitmètre entraîné par l'eau (16) pour contrôler lesdits moyens de soupape.",
            owner: "Harvey Softeners Limited",
            publicationDate: "21/10/2019"
        },
        {
            patentNumber: "WO9113031",
            title: "METHOD FOR TESTING A NUCLEAR FUEL ROD",
            summary: "L'invention concerne un dispositif pour le traitement de l'eau, tel qu'un adoucisseur d'eau, comportant deux composants pour le traitement de l'eau (1, 2) et des moyens de soupape pour que les deux composants soient rendus inopérants pour traiter l'eau à différents intervalles respectifs de sorte qu'au moins un composant (1, 2) soit fonctionnel tout le temps, caractérisé en ce que le moyen de soupape comporte des moyens de soupape de contrôle du débit d'eau et en ce que le dispositif comprend un débitmètre entraîné par l'eau (16) pour contrôler lesdits moyens de soupape.",
            owner: "Harvey Softeners Limited",
            publicationDate: "21/10/2019"
        },
        {
            patentNumber: "WO9113031",
            title: "METHOD FOR TESTING A NUCLEAR FUEL ROD",
            summary: "L'invention concerne un dispositif pour le traitement de l'eau, tel qu'un adoucisseur d'eau, comportant deux composants pour le traitement de l'eau (1, 2) et des moyens de soupape pour que les deux composants soient rendus inopérants pour traiter l'eau à différents intervalles respectifs de sorte qu'au moins un composant (1, 2) soit fonctionnel tout le temps, caractérisé en ce que le moyen de soupape comporte des moyens de soupape de contrôle du débit d'eau et en ce que le dispositif comprend un débitmètre entraîné par l'eau (16) pour contrôler lesdits moyens de soupape.",
            owner: "Harvey Softeners Limited",
            publicationDate: "21/10/2019"
        },
        {
            patentNumber: "WO9113031",
            title: "METHOD FOR TESTING A NUCLEAR FUEL ROD",
            summary: "L'invention concerne un dispositif pour le traitement de l'eau, tel qu'un adoucisseur d'eau, comportant deux composants pour le traitement de l'eau (1, 2) et des moyens de soupape pour que les deux composants soient rendus inopérants pour traiter l'eau à différents intervalles respectifs de sorte qu'au moins un composant (1, 2) soit fonctionnel tout le temps, caractérisé en ce que le moyen de soupape comporte des moyens de soupape de contrôle du débit d'eau et en ce que le dispositif comprend un débitmètre entraîné par l'eau (16) pour contrôler lesdits moyens de soupape.",
            owner: "Harvey Softeners Limited",
            publicationDate: "21/10/2019"
        },
        {
            patentNumber: "WO9113031",
            title: "METHOD FOR TESTING A NUCLEAR FUEL ROD",
            summary: "L'invention concerne un dispositif pour le traitement de l'eau, tel qu'un adoucisseur d'eau, comportant deux composants pour le traitement de l'eau (1, 2) et des moyens de soupape pour que les deux composants soient rendus inopérants pour traiter l'eau à différents intervalles respectifs de sorte qu'au moins un composant (1, 2) soit fonctionnel tout le temps, caractérisé en ce que le moyen de soupape comporte des moyens de soupape de contrôle du débit d'eau et en ce que le dispositif comprend un débitmètre entraîné par l'eau (16) pour contrôler lesdits moyens de soupape.",
            owner: "Harvey Softeners Limited",
            publicationDate: "21/10/2019"
        },
        {
            patentNumber: "WO9113031",
            title: "METHOD FOR TESTING A NUCLEAR FUEL ROD",
            summary: "L'invention concerne un dispositif pour le traitement de l'eau, tel qu'un adoucisseur d'eau, comportant deux composants pour le traitement de l'eau (1, 2) et des moyens de soupape pour que les deux composants soient rendus inopérants pour traiter l'eau à différents intervalles respectifs de sorte qu'au moins un composant (1, 2) soit fonctionnel tout le temps, caractérisé en ce que le moyen de soupape comporte des moyens de soupape de contrôle du débit d'eau et en ce que le dispositif comprend un débitmètre entraîné par l'eau (16) pour contrôler lesdits moyens de soupape.",
            owner: "Harvey Softeners Limited",
            publicationDate: "21/10/2019"
        }
    ]

    return <div className='flex flex-col flex-1 overflow-hidden'>
        <h1 className='p-2 text-md font-bold'>Brevets ({patents.length})</h1>
        <div className='flex flex-col flex-1 overflow-auto'>
            {
                patents.map(patent => {
                    return <div key={patent.title}
                        className='p-1 mx-2 my-1 border-b'>
                        <a className='flex hover:text-primary'
                            target="_blank"
                            href={`https://data.inpi.fr/brevets/${patent.patentNumber}`}>
                            <div className='flex-1'>
                                <div className='font-bold'>{patent.title}</div>
                                <div className='text-sm opacity-50'>{patent.owner}</div>
                            </div>
                            <div className='flex flex-col items-end'>
                                <div className='text-sm opacity-50'>{patent.patentNumber}</div>
                                <div className='text-xs opacity-50'>{patent.publicationDate}</div>
                            </div>

                        </a>


                        <div className='py-2'>
                            <div className='text-sm line-clamp-3'>{patent.summary}</div>
                        </div>
                    </div>
                })}
        </div>
    </div>
}