import { MarketReport } from '@xplorer/MarketReport';
import * as React from 'react'
import { WebSearch } from '../../core/WebSearch';
import { ProgressIndicator } from '@sleekly-ui/components/ProgressIndicator';
import { List } from '@sleekly-ui/components/List';
import { CompanyIcon } from '@sleekly-ui/icons/CompanyIcon';

type Props = {
    document: MarketReport
} & React.HTMLAttributes<HTMLDivElement>

export const MarketReportView: React.FC<Props> = ({ document }: Props) => {


    const [report, setReport] = React.useState(null);

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (document) {
            analyzeMarketReport(document);
        }
    }, [document]);

    const analyzeMarketReport = async (document: MarketReport) => {
        setLoading(true);
        const report: any = await WebSearch.analyzeMarketReport(document);
        console.log(report);

        const data = report.content;

        data.companies = data.companies.map(company => {
            return {
                name: company.name,
                id: company.name
            }
        })
        setReport(data);
        setLoading(false);
    }

    const renderCompanies = (company) => {
        return <div className='p-2 flex items-center space-x-2'>
            <CompanyIcon></CompanyIcon>
            <div className='font-bold text-sm'>{company.name}</div>
        </div>
    }

    const renderDomains = (domain) => {
        return <div className='p-2'>
            <div>
            <div className='font-bold text-sm'>{domain.label}</div>
            <div className='text-xs'>{domain.details}</div>
            </div>
        </div>
    }


    return <>
        <ProgressIndicator visible={loading}></ProgressIndicator>
        {
            !loading && report && <>
                <div className='my-2'>
                    <div className='text-xs font-bold mb-1 opacity-50'>Analyse</div>
                    <div>{report.summary}</div>
                </div>

                <div className='my-2'>
                    <div className='text-xs font-bold mb-1 opacity-50'>Taille du marché</div>
                    <div>{report.market_size}</div>
                </div>
                <div className='my-2'>
                    <div className='text-xs font-bold mb-1 opacity-50'>Estimation de la croissance</div>
                    <div>{report.growth_in_5_years}</div>
                </div>
                <div className='flex-1 flex overflow-hidden space-x-2 mt-2'>
                    <div className='flex-1 flex flex-col overflow-hidden'>
                        <div className='text-xs font-bold mb-1 opacity-50'>Principaux acteurs</div>
                        <List
                            items={report.companies}
                            renderItem={renderCompanies}>
                        </List>
                    </div>
                    <div className='flex-1 flex flex-col overflow-hidden'>
                        <div className='text-xs font-bold mb-1 opacity-50'>Principaux Domaines d'application</div>
                        <List
                            items={report.domains}
                            renderItem={renderDomains}>

                        </List>
                    </div>

                </div>
                <div className='flex items-center justify-center my-4'>
                    <button className="btn btn-sm btn-accent">Attacher à mon Projet</button>
                </div>
            </>
        }

    </>
}