import * as React from 'react'

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const SearchFiltersWidget: React.FC<Props> = ({ }: Props) => {

    const filters = [
        {
            label: "Rôles",
            items: [{
                label: "Fabricants",
                count: 145
            }, {
                label: "Utilisateurs",
                count: 49
            }]
        },
        {
            label: "Chiffre d'affaires",
            items: [{
                label: "<5M€",
                count: 145
            }, {
                label: "5-50 M€",
                count: 49
            },
            {
                label: ">50 M€",
                count: 33
            }]
        }
    ]

    return <div className='flex-1 flex flex-col overflow-auto border border-neutral-700 rounded-lg p-4'>
        {
            filters.map(group => {
                return <div key={group.label}
                    className='text-sm my-2'>
                    <div className='font-bold'>{group.label}</div>
                    {
                        group.items.map(item => {
                            return <div key={item.label}
                                className='ml-6 flex items-center space-x-2'>
                                    <input type="checkbox" className="checkbox checkbox-xs" />
                                <div>{item.label} </div>
                            </div>
                        })
                    }
                </div>
            })
        }
    </div>
}