import * as React from 'react'
import { Domain, DomainSummary } from '../core/Workflow'
import { ArrowDropRightIcon } from '@sleekly-ui/icons/ArrowDropRightIcon'
import { ArrowDropDownIcon } from '@sleekly-ui/icons/ArrowDropDownIcon'
import { DeleteIcon } from '@sleekly-ui/icons/DeleteIcon'

type Props = {
    domain: DomainSummary,
    onOpenSearchContext: (domain: Domain, keyword: string) => void
} & React.HTMLAttributes<HTMLDivElement>

export const DomainTreeNode: React.FC<Props> = ({ domain, onOpenSearchContext }: Props) => {

    const [expand, setExpand] = React.useState(false);

    const openSearchContext = (domain: Domain, keyword?: string) => () => {
        onOpenSearchContext(domain, keyword)
    }

    const toggleExpand = () => {
        setExpand((old) => {
            return !old
        });
    }

    const exploredStyleValue = 'opacity-100';
    const unexploredStyleValue = 'opacity-50';

    const exploredStyle = domain.explored ? exploredStyleValue : unexploredStyleValue;
    return <div key={domain.id}>
        <div className={`group flex items-center cursor-pointer space-x-2 ${exploredStyle}`}
        >

            <button className='btn btn-xs btn-ghost btn-circle'
                onClick={toggleExpand}>
                <span>
                    {
                        !expand && <ArrowDropRightIcon></ArrowDropRightIcon>
                    }
                    {
                        expand && <ArrowDropDownIcon></ArrowDropDownIcon>
                    }
                </span>
            </button>

            <span className='flex-1 cursor-pointer text-sm'
                onClick={openSearchContext(domain)}>{domain.label}</span>

            <div className='invisible group-hover:visible'>
                <button className='btn btn-xs btn-ghost btn-circle'>
                    <DeleteIcon></DeleteIcon>
                </button>
            </div>

        </div>
        {
            expand && <div className='ml-12'>
                {
                    domain.keywords.map(keyword => {
                        const explored = domain.exploredKeywords.includes(keyword);
                        const exploredStyle = explored ? exploredStyleValue : unexploredStyleValue;
                        return <div key={keyword}
                            className={`group flex text-sm ${exploredStyle}`}>
                            <span className={`flex-1 cursor-pointer text-sm`} onClick={openSearchContext(domain, keyword)}>{keyword}</span>
                            <div className='invisible group-hover:visible'>
                                <button className='btn btn-xs btn-ghost btn-circle'>
                                    <DeleteIcon></DeleteIcon>
                                </button>
                            </div>
                        </div>
                    })
                }
            </div>
        }

    </div>
}