import * as React from 'react'

import { Domain, Project, Workflow } from '../../core/Workflow'
import { DomainsOverviewWidget } from '../../components/DomainsOverviewWidget'
import { DomainCard } from '../../components/DomainCard'
import { SelectedCompaniesWidget } from '../../components/SelectedCompaniesWidget'
import { AI } from '../../core/AI'

import { MagicIcon } from '@sleekly-ui/icons/MagicIcon'
import { StackIcon } from '@sleekly-ui/icons/StackIcon'
import { AppState } from '@sleekly-ui/AppState'
import { ProgressIndicator } from '@sleekly-ui/components/ProgressIndicator'

import { CenterColumn, ColumnPageLayout, SideColumn } from '../../ext/Layout'
import { openDialog } from '../../ext/Dialog'


import { DomainPromptHistory } from './DomainPromptHistory'
import { ScreenTitle } from '@sleekly-ui/components/ScreenTitle'
import { List } from '@sleekly-ui/components/List'

type Props = {
    openSearchContext: (domain: Domain, keyword: string) => void
} & React.HTMLAttributes<HTMLDivElement>

export const DomainSearch: React.FC<Props> = ({ openSearchContext }: Props) => {

    const [suggestedApplications, setSuggestedApplications] = AppState.useState<Domain[]>(Workflow.SUGGESTED_DOMAINS, []);

    const [descriptionInput, setDescriptionInput] = React.useState<string>('');

    const [progressIndicatorVisible, setProgressIndicatorVisible] = React.useState(false);

    const [pinnedDomains] = AppState.useState<Domain[]>(Workflow.CANDIDATE_DOMAINS, [])

    const [currentProject] = AppState.useState<Project>('current-project');

    React.useEffect(() => {
        if (currentProject) {
            setDescriptionInput(currentProject.description);
        }
    }, [currentProject]);

    const suggestDomains = async (description: string) => {
        setSuggestedApplications([]);
        setProgressIndicatorVisible(true);
        const domains = await AI.suggestApplicationDomains(description);
        console.log(domains);
        setProgressIndicatorVisible(false);
        setSuggestedApplications(domains);
    }

    const pinDomain = (domain: Domain) => {
        Workflow.addDomain(domain);
    }

    const removePinnedDomain = (domain: Domain) => {
        Workflow.removeDomain(domain);
    }

    const openPromptHistoryDialog = () => {
        openDialog("domain-prompt-history");
    }

    const domainCardRenderer = (pinnedDomains, pinDomain, removePinnedDomain, openSearchContext) => (domain: Domain) => {
        const isPinned = pinnedDomains.find(d => d.id === domain.id);
        return <DomainCard key={domain.id}
            domain={domain}
            onPin={pinDomain}
            onRemovePin={removePinnedDomain}
            isPinned={!!isPinned}
            onSelectKeyword={openSearchContext}>
        </DomainCard>
    };
    return <ColumnPageLayout>
        <SideColumn>
            <DomainsOverviewWidget onSelectItem={openSearchContext}></DomainsOverviewWidget>
        </SideColumn>
        <CenterColumn>
            <ScreenTitle level='secondary'>Emergence de domaines</ScreenTitle>
            <div className={`px-8 flex-1 flex flex-col items-center overflow-hidden`}>

                {/* <div className='w-full'>
                    <h1 className='font-bold mb-2'>Génération de domaines</h1>
                </div> */}
                <div className='shadow-md bg-base-200 p-2 rounded-md w-full max-w-screen-md'>

                    <div className='flex items-baseline'>
                        <div className='text-xs opacity-50 mb-2'>Description de la technologie</div>
                        <div className='flex-1'></div>
                        <button className='btn btn-ghost btn-xs btn-circle'
                            onClick={openPromptHistoryDialog}>
                            <StackIcon></StackIcon>
                        </button>
                    </div>

                    <textarea className="textarea textarea-bordered w-full "
                        placeholder="Décrivez la technologie"
                        rows={3}
                        value={descriptionInput}
                        onChange={(e) => setDescriptionInput(e.target.value)}></textarea>


                    <div className='space-x-2 mt-2 flex justify-center'>
                        <button className='btn btn-sm btn-accent'
                            onClick={() => suggestDomains(descriptionInput)}
                            disabled={descriptionInput.length === 0}>
                            <MagicIcon></MagicIcon>
                            <span>Proposer des domaines d'application</span>
                        </button>
                    </div>
                </div>

                <ProgressIndicator visible={progressIndicatorVisible}></ProgressIndicator>

                <div className='flex-1 flex flex-col w-full max-w-screen-md mt-4 overflow-hidden'>
                    <List items={suggestedApplications}
                        renderItem={domainCardRenderer(pinnedDomains, pinDomain, removePinnedDomain, openSearchContext)}
                        emptyMessage={'Aucun domaine a afficher'}
                        emptyMessageDetails={'Décrivez votre technologie, executez ensuite l\'émergence des domaines'}
                    // emptyIllustration={() => {
                    //     return <CompanyIcon></CompanyIcon>
                    // }}
                    >
                    </List>
                </div>

            </div>
        </CenterColumn>
        {/* <SideColumn>
            <SelectedCompaniesWidget compact={true}></SelectedCompaniesWidget>
        </SideColumn> */}
        <DomainPromptHistory onSelectPrompt={(prompt) => {
            setDescriptionInput(prompt);
        }}></DomainPromptHistory>
    </ColumnPageLayout>
}