import * as React from 'react'

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const MainPage:React.FC<Props> = ({children, className}: Props) => {

    return <div className={`flex flex-col flex-1 overflow-hidden ${className}`}>
        {children}
    </div>
}