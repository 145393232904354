
export type NXResponse = {
    action?: 'query' | 'update' | 'delete' | 'signal'
    status: 'success' | 'failure',
    data?: any,
    message?: string
}

export type EntityRef = {
    kind: string,
    id?: string,
    ids?: string[],
}

export type PropertyQuery = {
    kind: string,
    name: string,
    expression: string,
}

export type SocketHandler = (event: NXResponse) => void;

export interface SocketCloser {
    close();
}

export class FlowaveClient {

    endpoint: string

    authToken: string | undefined

    constructor(endpoint: string, authToken?: string) {
        this.endpoint = endpoint;
        this.authToken = authToken;
    }


    async getEntities<T>(kind: string, ids?: string[]): Promise<T[]> {
        const response = await this.post('query', {
            kind,
            ids
        });
        if (response.status === 'success') {
            return response.data as T[]
        } else {
            throw Error(`"Request failed" ${response.message}`)
        }
    }

    async updateEntities<T>(data: T[]): Promise<T[]> {
        const response = await this.post('update', data);
        if (response.status === 'success') {
            return response.data as T[]
        } else {
            throw Error(`"Request failed" ${response.message}`)
        }
    }

    async queryProperty<T>(kind: string, name: string, expression: string): Promise<T[]> {
        const response = await this.post('query_property', {
            kind,
            name,
            expression
        });
        if (response.status === 'success') {
            return response.data as T[]
        } else {
            throw Error(`"Request failed" ${response.message}`)
        }
    }

    async deleteEntities<T>(data: T[]): Promise<T[]> {
        const entity_ids: string[] = data.map((d: any) => {
            return d.id;
        });
        const entityRef: EntityRef = {
            kind: (data[0] as any).kind,
            ids: entity_ids
        }
        const response = await this.post('delete', entityRef);
        if (response.status === 'success') {
            return response.data as T[]
        } else {
            throw Error(`"Request failed" ${response.message}`)
        }
    }

    async signal<T, U>(data: T): Promise<U> {
        try {
            const response = await this.post('signal', data);
            console.log(response);
            
            // if (response.status === 'success') {
            return response.data as U
            // } else {
            //     throw Error(`"Request failed" ${response.message}`)
            // }
        } catch (e) {
            throw Error(`"Request failed" ${e}`)
        }

    }

    async post(action: string, payload: any): Promise<NXResponse> {
        const resp = await fetch(this.endpoint, {
            method: 'POST',
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "Token": this.authToken
            },
            body: JSON.stringify({
                action: action,
                data: payload
            })
        });
        return await resp.json();
    }

    openSocket(path: string, handler: SocketHandler): SocketCloser {
        const protocol = window.location.protocol === "https:" ? "wss:" : "ws:";

        const host = window.location.host;
        const url = `${protocol}//${host}/${path}`

        const socket = new WebSocket(url);
        socket.onmessage = (event) => {
            const payload = JSON.parse(event.data) as NXResponse;
            handler(payload);
        };

        const closer: SocketCloser = {
            close() {
                socket.close();
            }
        };

        return closer;
    }
}