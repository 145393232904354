import * as React from 'react'
import { Tooltip } from './Tooltip'

type Props = {
    tooltipOrientation?: 'left' | 'right' | 'top' | 'bottom'
    entries: {
        icon: React.ReactElement,
        value: string,
        tooltip: string,

    }[]
} & React.HTMLAttributes<HTMLDivElement>

export const DataBadge: React.FC<Props> = ({ entries, tooltipOrientation }: Props) => {

    return <div className='flex items-center join px-1'>
        {
            entries.map((entry, index) => {
                return <div key={`${entry.value}-${index}`} className='join-item border px-2'>
                    <Tooltip text={entry.tooltip} tooltipOrientation={tooltipOrientation}>
                        <div className='flex items-end space-x-1 cursor-pointer hover:text-primary'>
                            {entry.icon}
                            <span className='text-xs mt-1'>{entry.value}</span>
                        </div>
                    </Tooltip>
                </div>
            })
        }

    </div>
}