import * as React from 'react'
import { Dialog } from '../ext/Dialog'
import { ProjectSettings } from './ProjectSettings'
import { Project } from '../core/Workflow'

type Props = {
    newProjectId: string,
    onCreateProject: (project: Project, action: string) => void
} & React.HTMLAttributes<HTMLDivElement>

export const NewProjectDialog: React.FC<Props> = ({ newProjectId, onCreateProject }: Props) => {

    const steps = [
        {
            id: 'parameters',
            label: 'Configuration'
        },
        {
            id: 'start',
            label: 'Démarrage'
        }
    ]

    const [activeStep, setActiveStep] = React.useState<number>(0);

    const [project, setProject] = React.useState<Project>({
        title: '',
        description: '',
        alt_descriptions: []
    });

    React.useEffect(() => {
        setProject({
            title: '',
            description: '',
            alt_descriptions: []
        });
    }, [newProjectId]);

    const nextStep = () => {
        setActiveStep(Math.min(activeStep + 1, steps.length));
    }

    const previousStep = () => {
        setActiveStep(Math.max(activeStep - 1, 0));
    }

    return <Dialog dialogId={NEW_PROJECT_DIALOG} title={'Nouveau projet'}>
        <div className='flex flex-col overflow-hidden'>

            <ul className="steps steps-horizontal w-full my-4">
                {
                    steps.map((step, index) => {
                        const activeStyle = index <= activeStep ? 'step-primary' : '';
                        return <li key={step.id}
                            className={`step ${activeStyle}`}>{step.label}</li>
                    })
                }
            </ul>

            {
                activeStep === 0 && <ProjectSettings project={project} onEdit={setProject}></ProjectSettings>
            }

            {
                activeStep === 1 && <div className='flex flex-col items-center'>
                    <h1 className='font-bold mt-4 tb-1 text-xl'>{project.title}</h1>
                    <p className='text-sm mb-4'>{project.description}</p>
                    <div className='flex space-x-3 mb-8'>
                        <div className='h-32 w-40 flex items-center justify-center shadow-lg p-4 rounded-md bg-accent hover:bg-primary hover:text-primary-content cursor-pointer'
                            onClick={() => onCreateProject(project, 'domains')}>
                            <span className='text-center'>Rechercher des domaines d'applications</span>
                        </div>

                        <div className='h-32 w-40 flex items-center justify-center  shadow-lg p-4 rounded-md bg-accent hover:bg-primary hover:text-primary-content cursor-pointer'
                            onClick={() => onCreateProject(project, 'search-companies')}>
                            <span className='text-center'>Rechercher des entreprises</span>
                        </div>
                    </div>
                </div>
            }

            <div className='flex justify-center space-x-2'>
                <button className='btn '
                    onClick={previousStep}
                    disabled={activeStep === 0}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                        className='h-5 w-5'><path d="M7 18V6H9V18H7ZM11 12 17 6V18L11 12Z"></path></svg>
                    <span>Précédent</span>

                </button>


                {
                    activeStep < (steps.length - 1) &&
                    <button className='btn btn-accent'
                        onClick={nextStep}
                        disabled={project.title.length === 0}>
                        <span>Suivant</span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                            className='h-5 w-5'><path d="M17 6 17 18H15L15 6 17 6ZM13 12 7 18V6L13 12Z"></path></svg>
                    </button>
                }

                {/* {
                    activeStep == (steps.length - 1) &&
                    <button className='btn btn-accent'
                        onClick={() => onCreateProject(project, null)}
                        disabled={project.title.length === 0 || project.description.length === 0}>
                        <span>Créer</span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                            className='h-5 w-5'><path d="M17 6 17 18H15L15 6 17 6ZM13 12 7 18V6L13 12Z"></path></svg>
                    </button>
                } */}
            </div>

        </div>
    </Dialog>
}

export const NEW_PROJECT_DIALOG = "new-project-dialog";