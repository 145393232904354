import * as React from 'react'
import { closeDialog, Dialog } from '../../ext/Dialog'
import { AppState } from '@sleekly-ui/AppState';

type Props = {
    onSelectPrompt: (prompt: string) => void
} & React.HTMLAttributes<HTMLDivElement>

export const DomainPromptHistory: React.FC<Props> = ({onSelectPrompt }: Props) => {

    const [currentProject] = AppState.useState<any>('current-project');

    const history = currentProject ? [].concat(currentProject.description, currentProject.alt_descriptions) : [];

    const selectPrompt = (prompt: string) => () => {
        closeDialog('domain-prompt-history');
        onSelectPrompt(prompt);
    }
    return <Dialog dialogId="domain-prompt-history"
        title='Historique de prompts'>
        <div className='h-80 overflow-auto'>
            {
                history && history.map(prompt => {
                    return <div key={prompt}
                        className='max-w-prose p-2 border-l-2 m-2 cursor-pointer hover:text-primary hover:border-primary'
                        onClick={selectPrompt(prompt)}>
                        {prompt}
                    </div>
                })
            }
        </div>
    </Dialog>
}