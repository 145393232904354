import * as React from 'react'

type Props = {
    scorePercent: number
} & React.HTMLAttributes<HTMLDivElement>

export const ScoreIndicator: React.FC<Props> = ({ scorePercent }: Props) => {

    return <div>
        {
            scorePercent < 30 && <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor" className='h-4 w-4'><g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" ></g><g id="SVGRepo_iconCarrier">
                    <g className='fill-orange-500'>
                        <path d="M15 15H.293l.853-.854L15 .293zm-1-1V2.707L2.707 14z" className='fill-orange-500'
                        ></path>
                        <path d="M6 14.5V10l-3.793 4.5z"
                            overflow="visible"></path>
                    </g>
                </g>
            </svg>
        }
        {
            (scorePercent >= 30 && scorePercent < 60) && <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor" className='h-4 w-4'><g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <g color="#000000" className='fill-yellow-500'>
                        <path d="M15 15H.293l.853-.854L15 .293zm-1-1V2.707L2.707 14z" overflow="visible" className='fill-yellow-500'></path>
                        <path d="M9 14.5V7l-6.793 7.5z" overflow="visible"></path> </g> </g></svg>
        }
        {
            scorePercent >= 60 && <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor" className='h-4 w-4'>
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" ></g>
                <g id="SVGRepo_iconCarrier"> <path d="M15 15H.293l.853-.854L15 .293z" color="#000000" overflow="visible" className='fill-green-500'></path>
                </g></svg>
        }
    </div>
}