import * as React from 'react'
import { PinControls, PinControlsAttributes } from './PinControls'
import { Domain } from '../core/Workflow'

type Props = {
    domain: Domain,
    hideDetails?: boolean,
    onSelectKeyword?: (domain: Domain, keyword: string) => void
} & React.HTMLAttributes<HTMLDivElement> & PinControlsAttributes

export const DomainCard: React.FC<Props> = ({
    domain,
    hideDetails,
    isPinned,
    onDelete,
    onPin,
    onRemovePin,
    onSelectKeyword
}: Props) => {

    const selectKeyword = (domain: Domain, keyword: string) => () => {
        onSelectKeyword && onSelectKeyword(domain, keyword);
    }


    return <div
        className='shadow-md bg-base-200 rounded-md p-2 my-1'>
        <div className='flex'>
            <div className='flex-1 font-bold cursor-pointer hover:text-primary'
                onClick={selectKeyword(domain, null)}
            >{domain.label}</div>
            <PinControls
                data={domain}
                isPinned={isPinned}
                onPin={onPin}
                onRemovePin={onRemovePin}
                onDelete={onDelete}
            ></PinControls>
        </div>

        {
            !hideDetails && <div className='text-sm'>{domain.details}</div>
        }
        <div className='flex flex-wrap'>
            {
                domain.keywords.map(keyword => {
                    return <div key={keyword}
                        className='badge m-1 cursor-pointer'
                        onClick={selectKeyword(domain, keyword)}>{keyword}</div>
                })
            }

        </div>
    </div>
}