import * as React from 'react'
import { CompanyOverview } from '../../core/Workflow'
import { WebSearch, WebSearchResultEntry, WebSearchResults } from '../../core/WebSearch'
import * as moment from 'moment'
import { ProgressIndicator } from '@sleekly-ui/components/ProgressIndicator'

type Props = {
    company: CompanyOverview
} & React.HTMLAttributes<HTMLDivElement>

export const CompanyNews: React.FC<Props> = ({ company }: Props) => {

    const [news, setNews] = React.useState<WebSearchResultEntry[]>([]);

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if(company) {
            fetchNews(company);
        }
    }, [company]);


    const fetchNews = async (company: CompanyOverview) => {
        setLoading(true);
        let results = await WebSearch.webSearch(`!news ${company.name}`, null)
        console.log(results);
        setLoading(false);
        setNews(results.results);
    }


    return <div className='flex flex-col flex-1 overflow-hidden'>
        <h1 className='p-2 text-md font-bold'>Actualités ({news.length})</h1>
        <ProgressIndicator visible={loading}></ProgressIndicator>
        <div className='flex flex-col flex-1 overflow-auto'>
            {
                news.map(item => {
                    const date = item.publishedDate ? moment(item.publishedDate).format('DD/MM/YYYY') : 'Date inconnue'
                    return <div key={item.url}
                        className='p-1 mx-2 my-1 border-b'>
                        <a className='flex space-x-2 hover:text-primary'
                            target="_blank"
                            href={item.url}>
                            <div className='flex-1'>
                                <div className='font-bold line-clamp-1 '>{item.title}</div>
                                <div className='text-sm opacity-50'>{item.engine}</div>
                            </div>
                            <div className='flex flex-col items-end'>
                                
                                <div className='text-xs opacity-50'>{date}</div>
                            </div>
                        </a>


                        <div className='py-2'>
                            <div className='text-sm line-clamp-3'>{item.content}</div>
                        </div>
                    </div>
                })}
        </div>
    </div>
}