import { ScreenTitle } from '@sleekly-ui/components/ScreenTitle'
import { CloseIcon } from '@sleekly-ui/icons/CloseIcon'
import { Dialog } from '@sleekly-ui/layout/Dialog'
import { MarketReport } from '@xplorer/MarketReport'
import * as React from 'react'
import { WebSearch } from '../../core/WebSearch'
import { ProgressIndicator } from '@sleekly-ui/components/ProgressIndicator'
import { MarketReportView } from './MarketReportView'
import { ExpandIcon } from '@sleekly-ui/icons/ExpandIcon'

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const MarketAnalysisDialog: React.FC<Props> = ({ }: Props) => {

    return <Dialog dialogId="market-analysis"
        className='w-full h-full'
        renderContent={(document: MarketReport, close) => {
            return <>
                <ScreenTitle>
                    {/* <ShoppingCartIcon className='h-6 w-6'></ShoppingCartIcon> */}
                    <div className='flex-1 space-x-2'>
                        <span>{document.title}</span>
                        <a className='btn btn-sm btn-square'
                            href={document.url}
                            target='_blank'>
                            <ExpandIcon></ExpandIcon>
                        </a>
                    </div>
                    <button className='btn btn-sm btn-circle btn-ghost focus:outline-none'
                        onClick={() => close()}>
                        <CloseIcon></CloseIcon>
                    </button>
                </ScreenTitle>
                <MarketReportView document={document}></MarketReportView>
            </>

        }}>
    </Dialog>
}