import * as React from 'react'
import { CompanyOverview } from '../../core/Workflow'

type Props = {
    company: CompanyOverview
} & React.HTMLAttributes<HTMLDivElement>

export const GeneralInfo: React.FC<Props> = ({ company }: Props) => {

    const companyProperties = [
        {
            label: 'SIREN',
            value: '305207169'
        },
        {
            label: 'SIRET',
            value: '30520716900601'
        },
        {
            label: 'Effectifs',
            value: '500 à 999'
        },
        {
            label: 'Chiffre d\'affaires',
            value: 'Non renseigné'
        },
        {
            label: 'Activité',
            value: company.description
        },
        {
            label: 'Activité principale de l\'établissement',
            value: 'Traitement et élimination des déchets dangereux'
        },
        {
            label: 'Caractère employeur de l\'établissement',
            value: 'Non'
        },
        {
            label: 'Date de création de l\'établissement',
            value: 'Traitement et élimination des déchets dangereux'
        },
        {
            label: 'Dénomination usuelle de l\'établissement',
            value: company.name
        }
    ]
    return <div className='flex flex-col flex-1 overflow-hidden'>
        <h1 className='p-2 text-md font-bold'>Informations sur l'établissement principal</h1>
        <div className='flex flex-col flex-1 overflow-auto'>
            {
                companyProperties.map(prop => {
                    return <div key={prop.label}
                        className='p-1 mx-2 my-1'>
                        <div className='text-xs opacity-50'>{prop.label}</div>
                        <div>{prop.value}</div>
                    </div>
                })}
        </div>
    </div>
}