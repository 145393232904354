import * as React from 'react'
import { DeleteIcon } from '@sleekly-ui/icons/DeleteIcon'

export type PinControlsAttributes = {
    data?: any
    isPinned?: boolean,
    onRemovePin?: (data: any) => void,
    onDelete?: (data: any) => void,
    onPin?: (data: any) => void,
}

type Props = {

} & React.HTMLAttributes<HTMLDivElement> & PinControlsAttributes

export const PinControls: React.FC<Props> = ({ data, isPinned, onDelete, onPin,onRemovePin }: Props) => {

    return <div className='flex'>
        {
            isPinned && <button className='btn btn-ghost btn-circle btn-xs text-primary'
                onClick={() => onRemovePin && onRemovePin(data)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className='h-4 w-4'><path d="M18 3V5H17V11L19 14V16H13V23H11V16H5V14L7 11V5H6V3H18Z"></path></svg>
            </button>
        }
        {
            onPin && !isPinned && <button className='btn btn-ghost btn-circle btn-xs'
                onClick={() => onPin(data)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                    className='h-4 w-4'>
                    <path d="M22.3126 10.1753L20.8984 11.5895L20.1913 10.8824L15.9486 15.125L15.2415 18.6606L13.8273 20.0748L9.58466 15.8321L4.63492 20.7819L3.2207 19.3677L8.17045 14.4179L3.92781 10.1753L5.34202 8.76107L8.87756 8.05396L13.1202 3.81132L12.4131 3.10422L13.8273 1.69L22.3126 10.1753Z"></path>
                </svg>
            </button>
        }
        {
            onDelete && !isPinned && <button className='btn btn-ghost btn-circle btn-xs'
                onClick={() => onDelete && onDelete(data)}>
                <DeleteIcon></DeleteIcon>
            </button>
        }
    </div>
}