import * as React from 'react'
import { Projects } from './projects/Projects'
import { AppState } from '@sleekly-ui/AppState'
import { ProjectHome } from './project-home/ProjectHome'
import { PageSwitcher, PageSwitcherItem } from '@sleekly-ui/layout/PageSwitcher'
import { useLoggedInUser } from './login/Login'

const views: PageSwitcherItem[] = [
    {
        id: 'select-projects',
        render: () => <Projects></Projects>
    },
    {
        id: 'project-home',
        render: () => <ProjectHome></ProjectHome>
    }
]

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const App: React.FC<Props> = ({ }: Props) => {

    const user = useLoggedInUser();

    const [currentViewId] = AppState.useState<string>('current-app-view', 'select-projects');

    return <div className='h-full w-full flex flex-col overflow-hidden'>
        <PageSwitcher activePageId={currentViewId} pages={views}></PageSwitcher>
    </div>
}